import { TimelineMax } from '@/vendor';
// import Config from '@/app/game/data/config';

export default class ScorePlopper
{
    constructor( settings )
    {
        this.sprite = new PIXI.Container();

        this.payload = settings.payload;

        this.textOptions = {
            fontFamily: 'LottoNL',
            fontSize: 36,
            fontWeight: 'bold',
            fill: 0xffffff
        };

        if( this.payload.score )
        {
            this.scoreText = new PIXI.Text( this.payload.score, this.textOptions );
        }

        this.scoreText.anchor.set( 0.5 );

        this.sprite.x = settings.x; // + ( Math.random() * 10 - 5 );
        this.sprite.y = settings.y; // + ( Math.random() * 10 - 5 );

        this.sprite.addChild( this.scoreText );

        if( this.prizeImg )
        {
            this.sprite.addChild( this.prizeImg );
        }

        this.startAnim();

        // this.eventHub.emit( 'foundSpecial', this.payload );
    }

    startAnim()
    {
        this.tl = new TimelineMax({ onComplete: this.seppoku.bind( this ) });
        this.tl.from( this.sprite, 0.1, { alpha: 0 }, 0 );
        this.tl.from( this.sprite.scale, 0.6, { x: 0, y: 0, ease: Back.easeOut.config( 4 ) }, 0 );
        this.tl.to( this.sprite, 0.3, { y: '-=50', ease: Sine.easeOut }, 0 );
        this.tl.to( this.sprite, 1.5, { y: '-=150', ease: Expo.easeIn }, 0.3 );
        this.tl.to( this.sprite, 0.2, { alpha: 0 }, 1.7 );
    }

    seppoku()
    {
        // this.eventHub.emit( 'foundSpecial', this.payload );

        this.tl.kill();
        this.tl = null;
        this.sprite.parent.removeChild( this.sprite );
    }
}
