import EventEmitter from 'eventemitter3';
import Victor from 'victor';
import EventHub from '@/app/game/handlers/EventHub';

/* eslint-disable */

export default class CollisionDetector extends EventEmitter
{
    constructor( blocklist )
    {
        super();

        this.eventHub = EventHub;
        this.blockList = blocklist;

        this.ticksLeft = null;
        this.collisionNormal = null;

        this.collisionType = 'ray'; // or ray
    }

    check( ball, rays, collider )
    {
        /*
        input:
            - maxIteration ( 1000 )
            - position ( to iterate over with rays.dir )
            - rays ( for direction )
            - blockList
        --
        check {N = 1000} positions for collision on both rays

        if collision occurs check other ray
            evalutate shortest
            adjust rays,
            adjust {ticks left} to spd (after initial collision) else just subtract ticks
        --
        rtn to shortest ray with collision, which gives:
            - ticksLeft ( was maxIteration, is now ball.spd )
            - precise collision pos ( sub-spd )
            - new dir ( both rays )

        loop {N = ball.spd} ticks

        if collision occurs
            evalutate shortest
            adjust ray,
            adjust {ticks left} to spd (after initial collision) else just subtract ticks

        end result:
            - collisionList
            - ticks until first collision
            - pos after sub-tick collision resolve
            - new dir
        */

        /*

        */

        let blocksLeft = this.blockList.filter( item => item.dead === true );


        // console.log( 'checking collision...' );
        let results = this.checkRayCollision( 3000, ball, rays, this.blockList );
        results.ballRef = ball;
        // console.log( 'result dir:', results.dir );

        if( results.dir )
        {
            if( Math.abs( results.dir.x ) <= 0.2 )
            {
                if( results.dir.x > 0 )
                {
                    results.dir.x = 0.2;
                }
                else
                {
                    results.dir.x = -0.2;
                }
            }

            if( Math.abs( results.dir.y ) <= 0.2 )
            {
                if( results.dir.y > 0 )
                {
                    results.dir.y = 0.2;
                }
                else
                {
                    results.dir.y = -0.2;
                }
            }
        }

        if( results.collisionList.length > 0 )
        {
            this.eventHub.emit( 'collisionAfter', results );
        }
    }

    checkRayCollision( iterations, ball, rays, blockList )
    {
        let rtnObj = {
            collisionList: [],
            ticks: null,
            subticks: null,
            pos: null,
            dir: null
        };

        var sortedResults = [];
        let sortedCollisions = [];
        let results = [];

        if( this.collisionType === 'pos' )
        {
            /*

            let perf8 = performance.now();

            //checking collision
            for( let ray of rays )
            {
                var pos = { x: ray.x + ball.x, y: ray.y + ball.y };
                let collision = false;

                for( let i = 0; i < iterations; i++ )
                {
                    if( i === 0 )
                    {
                        // console.log( 'start pos in collision:', pos );
                    }
                    pos.x += ray.dir.x;
                    pos.y += ray.dir.y;

                    if( pos.x < -40 || pos.x > 1140 || pos.y < -40 || pos.y > 2020 )
                    {
                        break;
                    }

                    for( let j = 0; j < blockList.length; j++ )
                    {
                        if( pos.x > this.blockList[j].x && pos.x <= this.blockList[j].x + this.blockList[j].w && pos.y > this.blockList[j].y && pos.y <= this.blockList[j].y + this.blockList[j].h )
                        {
                            if( this.blockList[j].dead )
                            {
                                continue;
                            }
                            // hit
                            collision = true;

                            // console.log( 'hit on:', pos );
                            // console.log( 'ball pos probably:', { x: pos.x - ray.x, y: pos.y - ray.y });

                            results.push({
                                collisionPos: { x: pos.x, y: pos.y },
                                ballPos: { x: pos.x - ray.x, y: pos.y - ray.y },
                                initialDir: ray.dir,
                                ticks: i - ball.ballSize / 2,
                                block: this.blockList[j],
                                ray: ray
                            });

                            break;
                        }
                    }

                    if( collision )
                    {
                        break;
                    }
                }
            }
            let perf9 = performance.now();
            */
        }
        else if( this.collisionType === 'ray' )
        {
            // new
            // rays[0] - blue
            // rays[1] - red
            let allCollisions = [];
            let collisions = [];

            /*
                // create the line representing the ball vector
                let bPos = new Victor( ball.x - rays[j].x, ball.y - rays[j].y );
                let bDir = new Victor( rays[j].dir.x, rays[j].dir.y );

                // preparing sides
                let blockSides = this.getBlockEdges( { x: this.blockList[n].x, y: this.blockList[n].y, w: this.blockList[n].w, h: this.blockList[n].h } );

                // determine side to get line to intersect with
                let oPos = blockSides[i][0];
                let oDir = blockSides[i][1];

                // get intersect offset
                let t = ( oPos.clone().subtract( bPos ) ).cross( oDir ) / ( bDir.clone().cross( oDir ) );

                // multiplyScalar the normal vector to reach the point
                let intersectionPoint = bPos.clone().add( bDir.clone().multiplyScalar( t ) );

                // subtract the object offset to get ball distances
                let distance = intersectionPoint.clone().subtract( oPos );

                // subtract the ray to get length until collision point, subtract the ballSize later;
                let ticks = intersectionPoint.clone().subtract( new Victor( ball.x - rays[j].x, ball.y - rays[j].y ) ).length();
            */

            for( let j = 0; j < rays.length; j++ )
            {
                let bPos = new Victor( ball.x - rays[j].x, ball.y - rays[j].y );
                let bDir = new Victor( rays[j].dir.x, rays[j].dir.y );

                // console.log( 'bPos, bDir', bPos, bDir );

                for( let n = 0; n < this.blockList.length; n++ )
                {
                    if( this.blockList[n].dead )
                    {
                        continue;
                    }

                    let block = { x: this.blockList[n].x, y: this.blockList[n].y, w: this.blockList[n].w, h: this.blockList[n].h };

                    if( bDir.x < 0 )
                    {
                        if( block.x > bPos.x + rays[j].x ) { continue };
                    }
                    else{
                        if( block.x + block.w < bPos.x + rays[j].x ){ continue };
                    }

                    if( bDir.y < 0 )
                    {
                        if( block.y > bPos.y + rays[j].y ) { continue };
                    }
                    else{
                        if( block.y + block.h < bPos.y + rays[j].y ){ continue };
                    }

                    let blockSides = this.getBlockEdges( block );
                    let distanceResults = [];

                    for( let i = 0; i < blockSides.length; i++ ) // loop over sides
                    {
                        let oPos = blockSides[i][0];
                        let oDir = blockSides[i][1];

                        let t = ( oPos.clone().subtract( bPos ) ).cross( oDir ) / ( bDir.clone().cross( oDir ) );

                        let intersectionPoint = bPos.clone().add( bDir.clone().multiplyScalar( t ) );
                        let distance = intersectionPoint.clone().subtract( oPos );

                        if( distance.y > block.h || distance.x > block.w || distance.y < -0.01 || distance.x < -0.01 )
                        {
                            // console.log( 'NO INTERSECT' );
                        }
                        else
                        {
                            // console.log( this.blockList[n].id, 'object side', blockSides[i][2], oPos, bDir );
                            let ticks = intersectionPoint.clone().subtract( new Victor( ball.x - rays[j].x, ball.y - rays[j].y ) ).length();

                            distanceResults.push({
                                block: this.blockList[n],
                                side: blockSides[i][2],
                                initialDir: rays[j].dir,
                                ticks: ticks - ball.ballSize,
                                ray: rays[j],
                                collisionPos: intersectionPoint,
                                ballPos: { x: intersectionPoint.x + rays[j].x, y: intersectionPoint.y + rays[j].y }
                            });

                            let sortedDistance = distanceResults.sort( ( a, b ) =>
                            {
                                return a.ticks > b.ticks ? 1 : ( b.ticks > a.ticks ? -1 : 0 );
                            } );

                            allCollisions.push( sortedDistance[0] );
                        }
                    }
                }
            }

            allCollisions.sort( ( a, b ) =>
            {
                return a.ticks > b.ticks ? 1 : ( b.ticks > a.ticks ? -1 : 0 );
            } );

            // console.log( allCollisions );
            let closestRay1 = allCollisions.filter( item => item.ray.name === '1' )[0];
            let closestRay2 = allCollisions.filter( item => item.ray.name === '2' )[0];

            if( closestRay1 )
            {
                collisions.push( allCollisions.filter( item => item.ray.name === '1' )[0] );
            }
            if( closestRay2 )
            {
                collisions.push( allCollisions.filter( item => item.ray.name === '2' )[0] );
            }

            sortedCollisions = collisions.sort( ( a, b ) =>
            {
                return a.ticks > b.ticks ? 1 : ( b.ticks > a.ticks ? -1 : 0 );
            } );

            // console.log( 'sortedCollisions[0]', sortedCollisions[0] );
        }
        /*
        result = {
            collisionPos: intersectionPoint,
            ticks: intersectionPoint.clone().subtract( new Victor( ball.x - rays[1].x, ball.y - rays[1].y ) ).length()
        }
        */

        /*
        now we have results = [];
        result = {
            collisionPos (of ray),
            ballPos (calculated by subtracting ray from collisionpos )
            initial dir (of the ray)
            ticks (how many times dir.x,dir.y is added to make collision)
            block (the poor bastard)
            ray: ray Reference
        }
        */

        // old;

        if( results.length >= 1 )
        {
            sortedResults = results.sort( ( a, b ) =>
            {
                return a.ticks > b.ticks ? 1 : ( b.ticks > a.ticks ? -1 : 0 );
            });
        }

        if( this.collisionType === 'ray' )
        {
            sortedResults = sortedCollisions;
        }

        var corner;
        var cornerV;
        var ballV;
        var cornerToBallDistance;

        if( ( sortedResults.length === 2 && sortedResults[0].block.id !== sortedResults[1].block.id && ( sortedResults[0].block.x === sortedResults[1].block.x || sortedResults[0].block.y === sortedResults[1].block.y ) ) || sortedResults.length === 1 )
        {
            var newDir;
            if( sortedResults.length === 1 )
            {
                let sanitizedX = parseFloat( sortedResults[0].collisionPos.x.toFixed( 2 ) );
                let sanitizedY = parseFloat( sortedResults[0].collisionPos.y.toFixed( 2 ) );

                if( sortedResults[0].collisionPos.x > sortedResults[0].block.x && sortedResults[0].collisionPos.x < sortedResults[0].block.x + sortedResults[0].block.w && ( sanitizedY === sortedResults[0].block.y || sanitizedY === sortedResults[0].block.y + sortedResults[0].block.h ) )
                {
                    newDir = sortedResults[0].initialDir.clone().multiplyScalarY( -1 );
                }
                else if( sortedResults[0].collisionPos.y > sortedResults[0].block.y && sortedResults[0].collisionPos.y < sortedResults[0].block.y + sortedResults[0].block.h && ( sanitizedX === sortedResults[0].block.x || sanitizedX === sortedResults[0].block.x + sortedResults[0].block.w ) )
                {
                    newDir = sortedResults[0].initialDir.clone().multiplyScalarX( -1 );
                }
                else
                {
                    console.warn( 'probably an error here' );
                    console.log( 'collisionPos', sortedResults[0].collisionPos );
                    console.log( 'block bb', sortedResults[0].block.x, sortedResults[0].block.y, sortedResults[0].block.w, sortedResults[0].block.h );
                }
            }
            else
            {
                newDir = this.calcNewDir( sortedResults[0] );
            }

            rtnObj.dir = newDir;

            let normalTicks = Math.floor( sortedResults[0].ticks / ball.spd );
            rtnObj.ticks = normalTicks;
            rtnObj.subticks = sortedResults[0].ticks % ball.spd;

            rtnObj.collisionList.push( sortedResults[0].block );
            rtnObj.pos = sortedResults[0].ballPos;
        }
        else if( sortedResults.length >= 2 )
        {

            let edge1 = this.getRectEdge( sortedResults[0].collisionPos, sortedResults[0].block );
            let edge2 = this.getRectEdge( sortedResults[1].collisionPos, sortedResults[1].block );

            if( sortedResults[0].block.id === sortedResults[1].block.id )
            {
                if( edge1[2] !== edge2[2] )
                {
                    corner = { x: null, y: null };
                    if( edge1[0].x === edge1[1].x )
                    {
                        corner.x = edge1[0].x;
                    }

                    if( edge1[0].y === edge1[1].y )
                    {
                        corner.y = edge1[0].y;
                    }

                    if( edge2[0].x === edge2[1].x )
                    {
                        corner.x = edge2[0].x;
                    }

                    if( edge2[0].y === edge2[1].y )
                    {
                        corner.y = edge2[0].y;
                    }

                    cornerV = new Victor( corner.x, corner.y );
                    ballV = new Victor( ball.x, ball.y );

                    let ticks = Math.floor( sortedResults[0].ticks / ball.spd );

                    cornerToBallDistance = ballV.clone().subtract( cornerV ).normalize().multiply( new Victor( ball.ballSize, ball.ballSize ) );

                    rtnObj.ticks = ticks;
                    rtnObj.subticks = sortedResults[0].ticks % ball.spd;
                }
                else
                {
                    var newDir = this.calcNewDir( sortedResults[0] );
                    // newDir.invert();

                    rtnObj.dir = newDir;

                    let normalTicks = Math.floor( sortedResults[0].ticks / ball.spd );

                    rtnObj.ticks = normalTicks;
                    rtnObj.subticks = sortedResults[0].ticks % ball.spd;
                }
            }
            else
            {
                corner = this.getClosestCorner( sortedResults[0].collisionPos, sortedResults[0].block );
                // console.log( 'corner', corner );
                if( corner !== null && ( corner.x && corner.y ) )
                {
                    cornerV = new Victor( corner.x, corner.y );
                    ballV = new Victor( ball.x, ball.y );

                    let ticks = ballV.clone().subtract( cornerV ).length();

                    cornerToBallDistance = ballV.clone().subtract( cornerV ).normalize().multiply( new Victor( ball.ballSize, ball.ballSize ) );
                    rtnObj.ticks = Math.floor( ticks / ball.spd );
                    rtnObj.subticks = ticks % ball.spd;

                    let newDir = new Victor( Math.cos( cornerToBallDistance.angleDeg() ), Math.sin( cornerToBallDistance.angleDeg() ) );
                    rtnObj.dir = newDir;

                    // console.warn( 'ik ben hier' );

                    cornerToBallDistance = null;
                }
                else
                {
                    /*
                    console.log( '3.2', sortedResults[0] );
                    var bounceDir = this.calcNewDir( sortedResults[0] );
                    console.log( sortedResults[0].initialDir );
                    console.log( bounceDir );

                    let newDir = ball.dir.clone();
                    newDir.multiplyScalarX( Math.cos( bounceDir.angleDeg() ) );
                    newDir.multiplyScalarY( Math.sin( bounceDir.angleDeg() ) );
                    rtnObj.dir = bounceDir;
                    */

                    let newDir = ball.dir.clone();
                    if( corner.x !== null )
                    {
                        newDir.multiplyScalarX( -1 );
                    }
                    else if( corner.y !== null )
                    {
                        newDir.multiplyScalarY( -1 );
                    }

                    rtnObj.dir = newDir;

                    let normalTicks = Math.floor( sortedResults[0].ticks / ball.spd );
                    rtnObj.ticks = normalTicks;
                    rtnObj.subticks = sortedResults[0].ticks % ball.spd;
                }
            }

            rtnObj.collisionList.push( sortedResults[0].block );
            rtnObj.pos = sortedResults[0].ballPos;
        }

        if( cornerToBallDistance )
        {
            rtnObj.pos.x += cornerToBallDistance.x;
            rtnObj.pos.y += cornerToBallDistance.y;
            let newDir = ball.dir.clone();
            newDir.multiplyScalarX( -Math.abs( Math.cos( cornerToBallDistance.angleDeg() ) ) );
            newDir.multiplyScalarY( -Math.abs( Math.sin( cornerToBallDistance.angleDeg() ) ) );
            rtnObj.dir = newDir;
            // rtnObj.pos = suggest
        }

        // if( rtnObj.subticks === null && rtnObj.collisionList.length > 0 )
        // {
        //     // console.warn( 'no subticks', rtnObj );
        // }

        return rtnObj;
    }

    calcNewDir({ ballPos, block, initialDir, collisionPos })
    {
        let corners = this.getRectEdge( collisionPos, block );

        // console.log( 'collision pos:', collisionPos );
        // console.log( 'block', { x: block.x, y: block.y, w: block.w, h: block.h });
        // console.log( 'hit corners:', corners );

        let cornerVector = new Victor( corners[1].x - corners[0].x, corners[1].y - corners[0].y ).normalize().rotate( -Math.PI / 2 );

        let resultDir = new Victor( initialDir.x, initialDir.y );

        // console.log( 'cornerVectors', cornerVector.x, cornerVector.y );

        if( Math.abs( cornerVector.x ) > Math.abs( cornerVector.y ) )
        {
            resultDir.flipDir = 'x';
            resultDir.multiplyScalarX( -1 );
        }
        else
        {
            resultDir.flipDir = 'y';
            resultDir.multiplyScalarY( -1 );
        }

        // console.warn( resultDir.flipDir );

        return resultDir;
    }

    getClosestCorner( pos, block )
    {
        if( !block )
        {
            return null;
        }

        var corner = { x: null, y: null };
        // console.log( 'pos', pos );
        // console.log( 'block', { x: block.x, y: block.y } );
        if( pos.x == block.x )
        {
            corner.x = block.x;
        }
        else if( pos.x == block.x + block.w )
        {
            corner.x = block.x + block.w;
        }
        else
        {
            corner.x = null;
        }

        if( pos.y == block.y )
        {
            corner.y = block.y;
        }
        else if( pos.y == block.y + block.h )
        {
            corner.y = block.y + block.h;
        }
        else
        {
            corner.y = null;
        }

        return corner;
    }

    getRectEdge(a, r)
    {
        // console.log( 'a, r', a, r );
        let rW = r.w;
        let rH = r.h;
        var rX = r.x + ( rW / 2 );
        var rY = r.y + ( rH / 2 );

        var slope = (rY - a.y) / (rX - a.x);
        var hsw = Math.round( slope * rW / 2 );
        var hsh = Math.round( ( rH / 2 ) / slope );
        var hh = Math.round( rH / 2 );
        var hw = Math.round( rW / 2 );
        var TOPLEFT = {x: rX - hw, y: rY + hh};
        var BOTTOMLEFT = {x: rX - hw, y: rY - hh};
        var BOTTOMRIGHT = {x: rX + hw, y: rY - hh};
        var TOPRIGHT = {x: rX + hw, y: rY + hh};

        // console.log( Math.abs( a.x - r.x ), Math.abs( a.y - ( r.y + r.h ) ) );

        if( Math.abs( a.x - r.x ) > Math.abs( a.y - ( r.y + r.h ) ) )
        {
            if (-hh <= hsw && hsw <= hh) {
                // line intersects
                if (rX < a.x) {
                    //right edge;
                    // console.log( 'right edge' );
                    return [BOTTOMRIGHT,TOPRIGHT, 'right'];
                } else if (rX > a.x) {
                    //left edge
                    // console.log( 'left edge' );
                    return [TOPLEFT, BOTTOMLEFT, 'left'];
                }
            }
            if ( -hw <= hsh && hsh <= hw) {
                if (rY > a.y) {
                    //top edge
                    // console.log( 'top edge' );
                    return [TOPLEFT, TOPRIGHT, 'top'];
                } else if (rY < a.y) {
                    // bottom edge
                    // console.log( 'bottom edge' );
                    return [BOTTOMRIGHT, BOTTOMLEFT, 'bottom'];
                }
            }
        }
        else
        {
            if ( -hw <= hsh && hsh <= hw) {
                if (rY > a.y) {
                    //top edge
                    // console.log( 'top edge' );
                    return [TOPLEFT, TOPRIGHT, 'top'];
                } else if (rY < a.y) {
                    // bottom edge
                    // console.log( 'bottom edge' );
                    return [BOTTOMRIGHT, BOTTOMLEFT, 'bottom'];
                }
            }
            if (-hh <= hsw && hsw <= hh) {
                // line intersects
                if (rX < a.x) {
                    //right edge;
                    // console.log( 'right edge' );
                    return [BOTTOMRIGHT,TOPRIGHT, 'right'];
                } else if (rX > a.x) {
                    //left edge
                    // console.log( 'left edge' );
                    return [TOPLEFT, BOTTOMLEFT, 'left'];
                }
            }
        }
    }

    getBlockEdges( pBlock )
    {
        let top = [ new Victor( pBlock.x, pBlock.y ), new Victor( 1, 0 ), 'top' ];
        let right = [ new Victor( pBlock.x + pBlock.w, pBlock.y ), new Victor( 0, 1 ), 'right' ];
        let bottom = [ new Victor( pBlock.x, pBlock.y + pBlock.h ), new Victor( 1, 0 ), 'bottom' ];
        let left = [ new Victor( pBlock.x, pBlock.y ), new Victor( 0, 1 ), 'left' ];

        return [ top, right, bottom, left ];
    }

    checkPaddle( ball, paddle )
    {
        /*
            // create the line representing the ball vector
            let bPos = new Victor( ball.x - rays[j].x, ball.y - rays[j].y );
            let bDir = new Victor( rays[j].dir.x, rays[j].dir.y );

            // preparing sides
            let blockSides = this.getBlockEdges( { x: this.blockList[n].x, y: this.blockList[n].y, w: this.blockList[n].w, h: this.blockList[n].h } );

            // determine side to get line to intersect with
            let oPos = blockSides[i][0];
            let oDir = blockSides[i][1];

            // get intersect offset
            let t = ( oPos.clone().subtract( bPos ) ).cross( oDir ) / ( bDir.clone().cross( oDir ) );

            // multiplyScalar the normal vector to reach the point
            let intersectionPoint = bPos.clone().add( bDir.clone().multiplyScalar( t ) );

            // subtract the object offset to get ball distances
            let distance = intersectionPoint.clone().subtract( oPos );

            // subtract the ray to get length until collision point, subtract the ballSize later;
            let ticks = intersectionPoint.clone().subtract( new Victor( ball.x - rays[j].x, ball.y - rays[j].y ) ).length();
        */
        let rays = [ ball.ray1, ball.ray2 ];
        let rtnObj = {
            ticks: null,
            ballPos: null,
            ballRef: ball
        };

        let tempTicks = null;

        for( let ray of rays )
        {
            let bPos = new Victor( ball.x - ray.x, ball.y - ray.y );
            let bDir = new Victor( ray.dir.x, ray.dir.y );

            let blockSides = this.getBlockEdges( paddle.collisionObj );

            let oPos = blockSides[0][0];
            let oDir = blockSides[0][1];

            let t = ( oPos.clone().subtract( bPos ) ).cross( oDir ) / ( bDir.clone().cross( oDir ) );
            let intersectionPoint = bPos.clone().add( bDir.clone().multiplyScalar( t ) );
            let distance = intersectionPoint.clone().subtract( oPos );

            if( distance.y > paddle.collisionObj.h || distance.x > paddle.collisionObj.w || distance.y < 0 || distance.x < 0 )
            {
                // console.log( 'NO INTERSECT' );
            }
            else
            {
                let ticksCalced = intersectionPoint.clone().subtract( new Victor( ball.x - ray.x, ball.y - ball.ballSize ) ).length();
                if( !tempTicks || tempTicks < ticksCalced )
                {
                    let normalTicks = Math.floor( ticksCalced / ball.spd );
                    rtnObj.ticks = normalTicks;
                    rtnObj.subticks = ticksCalced % ball.spd;

                    rtnObj.ballPos = { x: intersectionPoint.x + ray.x, y: intersectionPoint.y - ball.ballSize };
                }
            }
        }

        this.eventHub.emit( 'paddleCollisionAfter', rtnObj );
    }
}
