<template>
    <div :class="{ 'breakout-comp': true, 'hide-game': hideGame }" @mousemove="onInteraction" @touchmove="onInteraction" @click="tryReleaseBall">

        <div class="game-wrapper" ref="gameWrapper">
            <div class="score-wrapper">
                <div class="scoreboard">
                    <span>SCORE</span>
                    <span>{{ score }}</span>
                </div>

                <div class="prize-money-display">
                    <div class="red-elem w-label" ref="smallPrizeDisplay">
                        <div class="span-wrapper">
                            <span class="start-font">&euro;</span><span>{{ dottify( moneyCollected.toString().padEnd( 3, '0' ) ) }}</span>
                        </div>
                        <div class="pusher"></div>
                    </div>
                </div>
            </div>
            <div class="extra-border" ref="veld">
                <div class="limiter">
                    <div class="pusher"></div>
                    <div class="bg"></div>
                    <span class="highscore" v-show="highscore > 0">Highscore: {{ dottify( highscore ) }}</span>
                    <canvas ref="gameCanvas" width="1080" height="1920"></canvas>

                    <v-countdown :style="{ 'display': gameHasEnded ? 'none' : 'initial' }" v-if="readyForCountdown" :from="gameHasEnded ? 1 : 3" ref="countdown" @complete="countdownComplete"></v-countdown>

                    <div class="gameover-score-display" ref="gameoverScoreDisplay">
                        <div class="pixel-glow"></div>
                        <div class="red-elem" ref="bigPrizeDisplay">
                            <div class="span-wrapper">
                                <span class="start-font">&euro;</span><span ref="gameoverMoney">{{ dottify( endScoreDisplay ) }}</span>
                            </div>
                            <div class="pusher"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="skip-elem">
                <span v-if="maxMoneyCollected || noMorePrizeBlocks">Maximaal bedrag bereikt</span>
                <span v-else>Speel mee voor &euro;&nbsp;{{ dottify( moneyCollected.toString().padEnd( 3, '0' ) ) }}</span>

                <button class="red" :disabled="gameHasEnded" @click="forceGameover">Nu kans maken</button>
            </div>

            <div class="ball-reserves">
                <img class="ball-score-glow" src="/static/img/game/levensbal-glow.png" />
                <v-ball :dropped="ballsLeft < 3" :number="firstBall"></v-ball>
                <v-ball :dropped="ballsLeft < 2" :number="secondBall"></v-ball>
                <v-ball :dropped="ballsLeft < 1" :number="thirdBall"></v-ball>
            </div>
        </div>

        <!-- <button @click="killGame">Kill game</button> -->
    </div>
</template>

<style lang="scss" scoped>
    .ball-score-glow{
        opacity: 0;
    }
</style>

<script>
    import BreakoutGame from '@/app/game/Main';
    import LottoBall from '@/app/components/LottoBall';
    import Countdown from '@/app/components/Countdown';
    import { dottify } from '@/app/mixins';
    import { TweenMax, TimelineMax } from '@/vendor';

    export default {
        components: {
            'v-ball': LottoBall,
            'v-countdown': Countdown
        },

        mixins: [ dottify ],

        data()
        {
            return {
                hideGame: true,
                readyForCountdown: false,

                canReleaseBall: false,
                firstBall: 45,
                secondBall: 37,
                thirdBall: 1,
                ballsLeft: 3,

                noMorePrizeBlocks: false,
                gameHasEnded: false,
                endScoreDisplay: 0
            };
        },

        computed: {
            score()
            {
                return this.$store.getters[ 'game/score' ].toString().padStart( 7, '0' );
            },

            moneyCollected()
            {
                return this.$store.getters[ 'game/moneyCollected' ];
            },

            maxMoneyCollected()
            {
                return ( this.moneyCollected === 4500 );
            },

            highscore()
            {
                return this.$store.getters[ 'game/highscore' ];
            }
        },

        mounted()
        {
            this.game = new BreakoutGame({ view: this.$refs.gameCanvas });

            this.game.eventHub.on( 'collectedMoney', this.handlePrizeMoney );
            this.game.eventHub.on( 'maxCollectedMoneyReached', this.maxCollectedMoneyReached );
            this.game.eventHub.on( 'collectedScore', this.handleScore );
            this.game.eventHub.on( 'ballsLeft', this.handleRemainingBalls );
            this.game.eventHub.on( 'gameover', this.triggerGameComplete );
            this.game.eventHub.on( 'changeToBall7', this.changeToBall7 );

            // this.prepareAnims();
            TweenMax.set( this.$refs.gameoverScoreDisplay, { autoAlpha: 0 });

            this.resizeRef = this.onResize.bind( this );
            window.addEventListener( 'resize', this.resizeRef );
            this.onResize();

            setTimeout( () =>
            {
                // this.game.stop();
            }, 10000 );
        },

        beforeDestroy()
        {
            if( this.releaseTimeout )
            {
                clearTimeout( this.releaseTimeout );
            }

            window.removeEventListener( 'resize', this.resizeRef );
            this.game.destroy();
        },

        methods: {
            changeToBall7()
            {
                switch( this.ballsLeft )
                {
                        case 1:
                            this.thirdBall = 7;
                            break;
                        case 2:
                            this.secondBall = 7;
                            break;
                        case 3:
                            this.firstBall = 7;
                            break;
                }
            },

            onResize()
            {
                let veldWidth = this.$refs.veld.getBoundingClientRect().width;
                let scaleSmall = veldWidth * 0.32450980392156862745098039215686 / 331;
                let scaleBig = veldWidth * 0.69313725490196078431372549019608 / 331;
                // 0.27583

                if( window.innerHeight < 650 && window.innerWidth > window.innerHeight )
                {
                    TweenMax.set( this.$refs.smallPrizeDisplay, { transformOrigin: '0% 0%', scale: scaleSmall });
                }
                else
                {
                    TweenMax.set( this.$refs.smallPrizeDisplay, { transformOrigin: '50% 110%', scale: scaleSmall });
                }

                TweenMax.set( this.$refs.bigPrizeDisplay, { transformOrigin: '50% 50%', scale: scaleBig });

                this.game.eventHub.emit( 'resize' );
            },

            startCountdown()
            {
                this.readyForCountdown = true;
            },

            countdownComplete()
            {
                this.releaseBall();
                this.hideGame = false;
            },

            tryReleaseBall()
            {
                if( this.canReleaseBall === true )
                {
                    clearTimeout( this.releaseTimeout );
                    this.releaseBall();
                }
            },

            releaseBall()
            {
                this.game.eventHub.emit( 'releaseBall' );
                this.canReleaseBall = false;
            },

            handleScore( pAmount )
            {
                this.$store.dispatch( 'game/addScore', pAmount );
            },

            handlePrizeMoney()
            {
                this.$store.dispatch( 'game/addPrizeMoney', 900 );
            },

            maxCollectedMoneyReached()
            {
                this.noMorePrizeBlocks = true;
            },

            handleRemainingBalls( pAmount )
            {
                this.canReleaseBall = true;
                this.ballsLeft = pAmount;

                if( this.ballsLeft > 0 )
                {
                    this.releaseTimeout = setTimeout( () =>
                    {
                        this.releaseBall();
                    }, 3000 );
                }
                else
                {
                    this.game.stop();
                    this.triggerGameComplete();
                }
            },

            forceGameover()
            {
                this.triggerGameComplete();
            },

            triggerGameComplete()
            {
                this.game.stop();
                this.gameHasEnded = true;

                this.prepareAnims();
                // this.gameOverTl.play();
                // doe allemaal animaties enzo
            },

            onInteraction( e )
            {
                if( this.$route.fullPath === '/game' )
                {
                    e.preventDefault();
                    this.game.handleInput( e );
                }
            },

            killGame()
            {
                this.game.destroy();
            },

            prepareAnims()
            {
                this.gameOverTl = new TimelineMax();

                this.gameOverTl.to( '.prize-money-display', 0.3, { autoAlpha: 0 }, 0 );

                this.gameOverTl.fromTo( this.$refs.gameoverScoreDisplay, 0.5, { autoAlpha: 0 }, { autoAlpha: 1 }, 0 );
                this.gameOverTl.fromTo( this.$refs.gameoverScoreDisplay.querySelector( '.red-elem' ), 0.5, { scale: 0 }, { scale: 1, ease: Back.easeOut.config( 3 ) }, 0 );

                this.gameOverTl.to( this, 1, { roundProps: [ 'endScoreDisplay' ], endScoreDisplay: this.moneyCollected, ease: Circ.easeInOut }, 0 );

                let offsets = [ '16%', '50%', '84%' ];
                let ballValues = [ this.firstBall, this.secondBall, this.thirdBall ];

                for( let i = 3 - this.ballsLeft; i < 2; i++ )
                {
                    this.gameOverTl.fromTo( '.ball-score-glow', 0.7, { autoAlpha: 0, left: offsets[i] }, { autoAlpha: 1, left: offsets[i], yoyo: true, repeat: 1 }, i * 0.7 );
                    this.gameOverTl.add( () =>
                    {
                        console.log( 'adding', ballValues[i] * 100 );
                        this.handleScore( ballValues[i] * 100 );
                    }, i * 0.7 );
                }

                this.gameOverTl.add( () =>
                {
                    this.$store.dispatch( 'game/setHighscore' );
                    this.$router.replace( '/gegevens' );
                }, 3 );
            }
        }
    };
</script>
