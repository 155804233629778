import Victor from 'victor';
import EventHub from '@/app/game/handlers/EventHub';

export default class Ball extends PIXI.Sprite
{
    constructor( settings )
    {
        super();

        this.isBall7 = settings.isBall7;
        this.eventHub = EventHub;
        this.eventHub.on( 'newBallSpd', this.applyNewBallSpd.bind( this ) );

        this.streak = 0;
        this.ballSize = 18;
        this.rayLength = 3000;
        if( settings.isBall7 )
        {
            this.sprite = PIXI.Sprite.fromImage( '/static/img/game/ball7.png' );
        }
        else
        {
            this.sprite = PIXI.Sprite.fromImage( '/static/img/game/ball45.png' );
        }
        this.sprite.anchor.set( 0.5 );
        this.sprite.width = this.sprite.height = this.ballSize * 2;

        this.graphic = new PIXI.Graphics();

        this.graphic.beginFill( 0xffffff );
        this.graphic.alpha = 1;
        this.graphic.drawCircle( 0, 0, this.ballSize );

        this.addChild( this.graphic );
        this.addChild( this.sprite );

        this.spd = 16;
        this.dir = new Victor( 1, -1 ).normalize();

        this.x = settings.x ? settings.x : 586;
        this.y = settings.y ? settings.y : 350;

        let cos = Math.cos( this.dir.angle() );
        let sin = Math.sin( this.dir.angle() );

        this.ray1 = { name: '1', x: cos * this.ballSize, y: -sin * this.ballSize, dir: this.dir }; // red/ cos
        this.ray2 = { name: '2', x: -cos * this.ballSize, y: sin * this.ballSize, dir: this.dir }; // blue/ sine

        /* debug below */
        this.spdDirRay = new PIXI.Graphics();
        this.spdDirRay.lineStyle( 2, 0x00ff00 ); // speed + dir indicator
        this.spdDirRay.moveTo( 0, 0 );
        this.spdDirRay.lineTo( this.dir.x * this.spd * 10, this.dir.y * this.spd * 10 );
        this.spdDirRay.endFill();

        this.debugRay1 = new PIXI.Graphics();
        this.debugRay2 = new PIXI.Graphics();

        this.resolveRays();

        this.debugRay1.clear();
        this.debugRay1.lineStyle( 2, 0xff0000 );
        this.debugRay1.moveTo( this.ray1.x, this.ray1.y );
        this.debugRay1.lineTo( this.ray1.x + this.ray1.dir.x * this.rayLength, this.ray1.y + this.ray1.dir.y * this.rayLength );

        this.debugRay2.clear();
        this.debugRay2.lineStyle( 2, 0x0000ff );
        this.debugRay2.moveTo( this.ray2.x, this.ray2.y );
        this.debugRay2.lineTo( this.ray2.x + this.ray2.dir.x * this.rayLength, this.ray2.y + this.ray2.dir.y * this.rayLength );

        // this.addChild( this.spdDirRay );
        // this.addChild( this.debugRay2 );
        // this.addChild( this.debugRay1 );

        this.ticksLeft = null;
        this.removeList = null;

        this.isHome = true;

        console.log( this.sprite );
    }

    update()
    {
        if( this.isHome )
        {
            this.spd = 0;
            return;
        }

        if( this.y < 0 + this.ballSize )
        {
            this.dir.y = Math.abs( this.dir.y );
            this.checkCollisionOnPath();
        }

        if( this.y > 1920 + this.ballSize )
        {
            // this.dir.y = -Math.abs( this.dir.y );
            // this.checkCollisionOnPath();

            this.eventHub.emit( 'resetBall', this );
        }

        if( this.x < this.ballSize )
        {
            this.dir.x = Math.abs( this.dir.x );
            this.checkCollisionOnPath();
        }

        if( this.x + this.ballSize > 1080 )
        {
            this.dir.x = -Math.abs( this.dir.x );
            this.checkCollisionOnPath();
        }

        this.x += this.dir.x * this.spd;
        this.y += this.dir.y * this.spd;

        this.rotation += 0.03 * this.dir.x;

        // this.eventHub.emit( 'newMousePos', { x: this.x + this.ballSize / 2 + 5, y: this.y });

        this.spdDirRay.clear();
        this.spdDirRay.lineStyle( 2, 0x00ff00 );
        this.spdDirRay.moveTo( 0, 0 );
        this.spdDirRay.lineTo( this.dir.x * this.spd * 10, this.dir.y * this.spd * 10 );

        this.debugRay1.clear();
        this.debugRay1.lineStyle( 2, 0xff0000 );
        this.debugRay1.moveTo( this.ray1.x, this.ray1.y );
        this.debugRay1.lineTo( this.ray1.x + this.ray1.dir.x * this.rayLength, this.ray1.y + this.ray1.dir.y * this.rayLength );

        this.debugRay2.clear();
        this.debugRay2.lineStyle( 2, 0x0000ff );
        this.debugRay2.moveTo( this.ray2.x, this.ray2.y );
        this.debugRay2.lineTo( this.ray2.x + this.ray2.dir.x * this.rayLength, this.ray2.y + this.ray2.dir.y * this.rayLength );
    }

    applyNewBallSpd( pSpd )
    {
        this.spd = pSpd;
        this.eventHub.emit( 'ballSpd', this.spd );
        this.eventHub.emit( 'requestCollisionCheck', this, [ this.ray1, this.ray2 ] );
    }

    reset( pBallsLeft )
    {
        if( pBallsLeft > 0 )
        {
            this.isHome = true;
        }

        this.spd = 0;
        this.dir.y = -1;

        switch( pBallsLeft )
        {
            case 2:
                this.sprite.texture = PIXI.Texture.from( '/static/img/game/ball37.png' );
                break;
            case 1:
                this.sprite.texture = PIXI.Texture.from( '/static/img/game/ball1.png' );
                break;
            case 0:
                this.eventHub.emit( 'gameover' );
                break;
        }

        this.resolveRays();
    }

    release( pSpd )
    {
        this.isHome = false;
        this.spd = pSpd || 10;
        this.dir.normalize();

        this.resolveRays();

        // console.log( 'release', this.dir.angle() );
        this.eventHub.emit( 'requestCollisionCheck', this, [ this.ray1, this.ray2 ] );
    }

    changeDir( pDir )
    {
        this.dir = pDir.normalize();
        this.resolveRays();
        this.eventHub.emit( 'requestCollisionCheck', this, this.rays );
    }

    checkCollisionOnPath()
    {
        this.resolveRays();

        this.eventHub.emit( 'requestCollisionCheck', this, this.rays );
        if( this.dir.y > 0 )
        {
            this.eventHub.emit( 'requestPaddleCheck', this );
        }
    }

    resolveRays()
    {
        let cos = Math.cos( this.dir.angle() );
        let sin = Math.sin( this.dir.angle() );

        this.ray1 = { name: '1', x: sin * this.ballSize, y: -cos * this.ballSize, dir: this.dir }; // red/ cos
        this.ray2 = { name: '2', x: -sin * this.ballSize, y: cos * this.ballSize, dir: this.dir }; // blue/ sine
    }

    get rays()
    {
        return [ this.ray1, this.ray2 ];
    }
}
