<template>
    <div class="naw-view">
        <template v-if="moneyCollected === 0">
            <h2 class="fatter">Helaas, probeer het zo opnieuw!</h2>
            <p>Vul je gegevens in. Speel Bricks daarna nog een keer om kans te maken op maximaal &euro;&nbsp;4.500!</p>
        </template>
        <template v-else>
            <h2 class="fatter">Goed gespeeld!</h2>
            <p>Vul je gegevens om kans <br/>te maken op <strong>&euro;&nbsp;{{ dottify( moneyCollected ) }}</strong></p>
        </template>

        <v-form @formComplete="toResult" ref="customForm"></v-form>
    </div>
</template>

<style lang="scss">

</style>

<script>
    import { dottify } from '@/app/mixins';
    import Form from '@/app/components/Form';

    export default {
        name: 'Naw',

        components: {
            'v-form': Form
        },

        mixins: [ dottify ],

        computed: {
            moneyCollected()
            {
                return this.$store.getters[ 'game/moneyCollected' ];
            },

            score()
            {
                return this.$store.getters[ 'game/score' ];
            }
        },

        methods: {
            toResult()
            {
                this.$router.replace( '/bedankt' );
            }
        }
    };
</script>
