import EntryProxy from '@/app/proxies/EntryProxy';

export default {
    namespaced: true,

    state: {
        entry: null
    },

    getters: {
        get: state => state.entry
    },

    mutations: {
        savedEntry: ( state, payload ) =>
        {
            state.entry = payload;
        }
    },

    actions: {
        save( context, payload )
        {
            // console.log( 'inside save action' );
            return new EntryProxy()
                .create( payload )
                .then( ( response ) =>
                {
                    // console.log( 'then' );
                    let entry = Object.assign( payload, response );
                    context.commit( 'savedEntry', entry );
                    return response;
                })
                .catch( ( error ) =>
                {
                    // console.log( 'caught' );
                    return Promise.reject( error );
                });
        }
    }
};
