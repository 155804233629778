<template>
    <div class="custom-input">
        <div :class="{ 'row': true, 'gender-row': true, 'has-error': errors.has('gender') }">
            <div class="col-6">
                <v-input name="gender" id="gender-m" type="radio" radioValue="male" v-model="gender" v-validate="'required'">
                    <label for="gender-m">De Heer</label>
                </v-input>
            </div>
            <div class="col-6">
                <v-input name="gender" id="gender-v" type="radio" radioValue="female" v-model="gender" v-validate="'required'">
                    <label for="gender-v">Mevrouw</label>
                </v-input>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <span class="error text-center" v-if="errors.first('gender')" style="width: 100%; display:inline-block;">Aanhef is verplicht.</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .custom-input label{
        font-size:1rem !important;
    }
</style>

<script>
    // import { rules, labels } from '@/app/i18n';
    import CustomInput from '@/app/components/CustomInputUsedForGender';

    export default {
        inject: [ '$validator' ],

        components: {
            'v-input': CustomInput
        },

        props: {
            value: {
                type: String,
                default: null
            }
        },

        data()
        {
            return {
                state: null,
                gender: null
            };
        },

        computed: {
            errors()
            {
                return this.$validator.errors;
            }
        },

        watch: {
            'value': function( pVal )
            {
                if( pVal )
                {
                    this.gender = pVal;
                    this.$validator.errors.remove( 'gender' );
                }
            },

            'gender': function( pVal )
            {
                this.validate();
            }
        },

        mounted()
        {
            if( this.value )
            {
                this.gender = this.value;
            }
        },

        methods: {
            validate()
            {
                // console.log( 'validate', this.$validator.errors.has( 'gender' ), this.value, this.gender );
                if( this.gender )
                {
                    this.$emit( 'input', this.gender );
                }
            }
        }
    };
</script>
