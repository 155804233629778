import EventHub from '@/app/game/handlers/EventHub';

export default class DebugPanel extends PIXI.Container
{
    constructor( settings )
    {
        super();

        this.appRef = settings.appRef;

        this.eventHub = EventHub;

        this.textStyle = {
            fontFamily: 'Courier New',
            fontSize: 42,
            fill: 0xffffff
        };

        this.fpsText = new PIXI.Text( 'FPS: 0', this.textStyle );
        this.spdText = new PIXI.Text( 'SPD: 0', this.textStyle );
        this.posText = new PIXI.Text( 'POS: ', this.textStyle );

        this.spdText.y = 50;
        this.posText.y = 100;

        this.ballSpd = 0;

        this.addChild( this.fpsText );
        this.addChild( this.spdText );
        this.addChild( this.posText );

        this.eventHub.on( 'ballSpd', this.setBallSpd.bind( this ) );
        this.eventHub.on( 'newMousePos', this.setMousePos.bind( this ) );
    }

    setBallSpd( pSpd )
    {
        this.ballSpd = pSpd;
    }

    setMousePos( pPos )
    {
        this.posText.text = 'POS: ' + pPos.x.toFixed( 0 ) + ', ' + pPos.y.toFixed( 0 );
    }

    update()
    {
        this.fpsText.text = 'FPS: ' + this.appRef.ticker.FPS.toFixed( 2 );
        this.spdText.text = 'SPD: ' + this.ballSpd;
    }
}
