import EventHub from '@/app/game/handlers/EventHub';

export default class InputHandler
{
    constructor( settings )
    {
        this.view = settings.view;
        this.rect = this.view.getBoundingClientRect();
        this.factor = 1080 / this.rect.width;

        this.eventHub = EventHub;
        this.eventHub.on( 'resize', this.resize.bind( this ) );
    }

    resize()
    {
        this.rect = this.view.getBoundingClientRect();
        this.factor = 1080 / this.rect.width;
    }

    interaction( e )
    {
        if( e.type === 'mousemove' )
        {
            this.onInteractionMove( e );
        }

        if( e.type === 'touchmove' )
        {
            this.onTouchMove( e );
        }

        if( e.type === 'mousewheel' )
        {
            this.onInteractionWheel( e );
        }
    }

    onInteractionMove( e )
    {
        let coords = this.getCoords( e );

        this.eventHub.emit( 'newMousePos', coords );
    }

    onTouchMove( e )
    {
        let coords = this.getCoords( e.touches[0] );
        this.eventHub.emit( 'newMousePos', coords );
    }

    onInteractionWheel( e )
    {
        // this.eventHub.emit( 'newBallSpd',  e.deltaY / Math.abs( e.deltaY ) );
    }

    getCoords( e )
    {
        let x = ( e.clientX - this.rect.left ) * this.factor;
        let y = ( e.clientY - this.rect.top ) * this.factor;

        return { x, y };
    }
}
