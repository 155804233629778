<template>
    <div class="game-view">
        <keep-alive>
            <router-view ref="explainView"></router-view>
        </keep-alive>

        <ynk-game ref="game"></ynk-game>
    </div>
</template>

<style lang="scss">
    .skip-button{
        position:absolute;
        bottom:0;
        left:0;
    }
</style>

<script>
    import Breakout from '@/app/game/Breakout';

    export default {
        name: 'Game',

        components: {
            'ynk-game': Breakout
        },

        mounted()
        {
            this.$store.dispatch( 'game/resetScores' );

            if( this.$route.name === 'Game' )
            {
                this.$refs.game.startCountdown();
            }
        }
    };
</script>
