<template>
    <div class="explain-view">
        <div class="panel">
            <v-slider ref="slider" autoplay pauseOnHover @userHasInteracted="userHasInteracted">
                <div>
                    <div class="slider-content" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">
                        <img class="visual" src="/static/img/explain/uitleg1.jpg" />
                        <h2>Kaats de bal</h2>
                        <p>Beweeg het plankje met je muis of vinger en kaats de Lotto&nbsp;bal richting de Bricks. Een&nbsp;Brick verdwijnt als de bal er tegenaan&nbsp;komt.</p>
                        <button :class="{ 'hidden': !showPlayButton }" @click="toGame">Spelen</button>
                    </div>
                </div>
                <div>
                    <div class="slider-content" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">
                        <img class="visual" src="/static/img/explain/uitleg2.jpg" />
                        <h2>Bonus Bricks</h2>
                        <p>Niet alle Bricks zijn geel. Bonus Bricks hebben een andere kleur. Raak je deze Brick met de bal, dan valt deze naar beneden. Probeer deze Brick te vangen met je plankje om de bonus te&nbsp;verzamelen.</p>
                        <button :class="{ 'hidden': !showPlayButton }" @click="toGame">Spelen</button>
                    </div>
                </div>
                <div>
                    <div class="slider-content" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">
                        <img class="visual" src="/static/img/explain/uitleg3.jpg" />
                        <h2>Rode Bonus Bricks</h2>
                        <p>De Rode Bonus Bricks zijn het belangrijkst. Deze&nbsp;verhogen de prijs waarop je kans maakt. Elke&nbsp;Rode&nbsp;Brick is &euro;&nbsp;900 waard en de eerste krijg je&nbsp;cadeau. Er zijn er nog 4&nbsp;over. Je&nbsp;kunt zo dus &euro;&nbsp;4.500 bij elkaar sparen!</p>
                        <button :class="{ 'hidden': !showPlayButton }" @click="toGame">Spelen</button>
                    </div>
                </div>
                <div>
                    <div class="slider-content" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">
                        <img class="visual" src="/static/img/explain/uitleg4.jpg" />
                        <h2>Overige Bonus Bricks</h2>
                        <p>Overige Bonus&nbsp;Bricks kunnen je plankje breder maken, een extra bal toevoegen aan het spel of zelfs extra punten&nbsp;opleveren.</p>
                        <button :class="{ 'hidden': !showPlayButton }" @click="toGame">Spelen</button>
                    </div>
                </div>
                <div>
                    <div class="slider-content" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">
                        <img class="visual" src="/static/img/explain/uitleg5.jpg" />
                        <h2>Grijze Bricks</h2>
                        <p>In elk spel zitten 3&nbsp;Grijze&nbsp;Bricks. Deze&nbsp;kun je niet wegspelen en blijven altijd&nbsp;staan.</p>
                        <button :class="{ 'hidden': !showPlayButton }" @click="toGame">Spelen</button>
                    </div>
                </div>
                <div>
                    <div class="slider-content" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">
                        <img class="visual" src="/static/img/explain/uitleg6.jpg" />
                        <h2>Einde spel</h2>
                        <p>Zodra je alle Bricks hebt weggespeeld, je 3 levens op zijn of je op ‘nu kans maken’ hebt geklikt is het spel afgelopen. Je maakt daarna kans op het bij elkaar gespeelde&nbsp;bedrag.</p>
                        <button :class="{ 'hidden': !showPlayButton }" @click="toGame">Spelen</button>
                    </div>
                </div>
            </v-slider>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .panel{
        overflow:hidden;
    }
</style>

<script>
    import { TimelineMax, TweenMax } from '@/vendor';
    import Slider from '@/app/components/Slider';
    import { tracking } from '@/app/mixins';

    export default {
        components: {
            'v-slider': Slider
        },

        mixins: [ tracking ],

        data()
        {
            return {
                showPlayButton: false
            };
        },

        mounted()
        {
            setTimeout( () =>
            {
                this.showPlayButton = true;
            }, 5000 );
        },

        methods: {
            toGame( e )
            {
                // this.trackEvent( 'Klik op button - Spelen', 'Link tracking', window.location.origin + '/spel' );
                this.$router.replace( '/game' );
            },

            onMouseLeave( e )
            {
                if( this.$refs.slider )
                {
                    this.$refs.slider.onMouseLeave( e );
                }
            },

            onMouseEnter( e )
            {
                if( this.$refs.slider )
                {
                    this.$refs.slider.onMouseEnter( e );
                }
            },

            userHasInteracted()
            {
                this.showPlayButton = true;
                TweenMax.fromTo( '.slider-content button', 0.3, { autoAlpha: 0, height: 0 }, { autoAlpha: 1, height: 'auto' });
            },

            getAnimateIn()
            {
                let tl = new TimelineMax();

                TweenMax.set( '.explain > .panel > div', { autoAlpha: 0 });

                tl.from( '.explain > .panel', 0.5, { y: '200%', ease: Sine.easeOut });
                tl.fromTo( '.explain > .panel', 0.5, { width: '5px', minWidth: '5px' }, { width: '100vw', minWidth: '0px' });
                tl.to( '.explain > .panel > div', 0.6, { autoAlpha: 1 }, 0.8 );

                return tl;
            },

            getAnimateOut()
            {
                let tl = new TimelineMax();
                tl.fromTo( '.explain > .panel', 0.5, { maxWidth: window.innerWidth + 'px' }, { maxWidth: '5px', ease: Sine.easeOut });
                tl.to( '.explain > .panel', 0.5, { y: '-200%' });
                tl.to( '.explain > .panel > div', 0.2, { autoAlpha: 0 }, 0 );
                tl.to( '.explain', 0.5, { autoAlpha: 0 });

                return tl;
            }
        }
    };
</script>
