<template>
    <div class="form-input">
        <label v-if="inputType !== 'checkbox'" :for="input">{{ inputLabel }}</label>
        <input
            :id="input"
            :name="input"
            :type="inputType"
            :class="{ 'error': errors.has( input ), 'correct': fields[input] && fields[input].valid && fields[input].validated && inputValue }"
            :autocomplete="autocompletes[input]"
            :tabindex="tabindex"
            v-validate="rules[input]"
            v-model="inputValue"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur" />
        <div class="inner-wrapper" v-if="inputType === 'checkbox'">
            <div class="indicator"></div>
            <label :for="input" ref="textLabel"><slot></slot></label>
        </div>

        <span class="error">{{ errors.first( input ) }}</span>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Vue from 'vue';
    import { tracking, browserCheck } from '@/app/mixins';

    export default {
        inject: [ '$validator' ],

        mixins: [ tracking, browserCheck ],

        props: {
            input: {
                type: String,
                default: 'firstname'
            },

            inputType: {
                type: String,
                default: 'text'
            },

            value: {
                type: [ String, Boolean ],
                default: ''
            },

            label: {
                type: String,
                default: null
            },

            cancelMobileKeyboard: {
                type: Boolean,
                default: false
            },

            tabindex: {
                type: String,
                default: ''
            }
        },

        data()
        {
            return {
                typingTimeout: null,
                inputValue: this.value,
                rules: {
                    gender: { required: true },
                    firstname: { required: true, min: 2, regex: /^[a-z ,.'-]+$/i },
                    infixname: { required: false, min: 1, regex: /^[a-z ,.'-]+$/i },
                    surname: { required: true, min: 2, regex: /^[a-z ,.'-]+$/i },
                    zipcode: { required: true, regex: /^[0-9]{4} {0,1}[A-Za-z]{2}$/ },
                    house_num: { required: true, min: 1, min_value: 1, max: 5 },
                    house_num_add: { required: false, max: 10 },
                    // birthday: { required: true, max: 10, regex: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/ },
                    birthday: { required: true, max: 10 },
                    birthdayFormatted: { required: true, date_format: 'DD-MM-YYYY' },

                    // eslint-disable-next-line
                    telephone: { required: false, regex: /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/ },

                    emailaddress: { required: true, email: true },
                    age: { required: true }
                },
                autocompletes: {
                    firstname: 'given-name',
                    infixname: 'additional-name',
                    surname: 'family-name',
                    zipcode: 'postal-code',
                    house_num: 'off',
                    house_num_add: 'off',
                    telephone: 'tel',
                    emailaddress: 'email',
                    birthday: 'birthday'
                }
            };
        },

        computed: {
            inputLabel()
            {
                return this.label || this.$validator.dictionary.getAttribute( this.$validator.dictionary.locale, this.input );
            }
        },

        watch: {
            value( pVal )
            {
                this.inputValue = this.value;

                if( this.fields[ this.inputValue ] && this.fields[ this.inputValue ].validated )
                {
                    this.$validator.validate( this.inputValue );
                }
            }
        },

        mounted()
        {
            // console.log( 'form-elem', this.$validator );
        },

        methods: {
            onFocus( e )
            {
                if( this.cancelMobileKeyboard && this.browserCheck.isMobile )
                {
                    e.preventDefault();
                    e.target.blur();
                }

                if( this.inputType === 'checkbox' )
                {
                    // this.trackEvent( 'Klik op checkbox', 'Funnel events', 'Checkbox - ' + this.$refs.textLabel.innerHTML + ' - ' + this.inputValue );
                }
                else
                {
                    this.trackEvent( 'Klik op input', 'Funnel events', this.inputLabel );
                }
            },

            onInput( e )
            {
                if( this.inputType === 'checkbox' )
                {
                    Vue.nextTick( () =>
                    {
                        this.trackEvent( 'Klik op checkbox', 'Funnel events', ( this.inputValue ? 'Aangevinkt' : 'Uitgevinkt' ) + ' - ' + this.$refs.textLabel.innerHTML );
                    });
                }
                this.$emit( 'input', this.inputType === 'checkbox' ? e.target.checked : e.target.value );
            },

            onBlur( e )
            {
                this.$emit( 'blur', e );
            }
        }
    };
</script>
