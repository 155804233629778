<template>
    <div class="wrapper countdown">
        <template v-for="n in from">
            <span class="number" ref="number" :style="{ 'font-size': fontSize }" >{{ from - ( n - 1 ) }}</span>
        </template>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { TimelineMax, TweenMax } from '@/vendor';

    export default {
        props: {
            'fontSize': {
                type: String,
                default: '200px'
            },

            'from': {
                type: Number,
                default: 3
            },

            'trigger': {
                type: Boolean,
                default: true
            }
        },

        watch: {
            'from': function()
            {
                this.buildTimeline();
            },

            'trigger': function( pVal )
            {
                if( pVal )
                {
                    this.tl.play();
                }
            }
        },

        mounted()
        {
            TweenMax.set( this.$refs.number, { transformOrigin: '50% 50%', x: '-50%', y: '-50%' });

            this.buildTimeline();
        },

        methods: {
            buildTimeline()
            {
                this.tl = new TimelineMax({
                    delay: 0.5,
                    paused: !this.trigger,
                    onComplete: this.onComplete
                });

                this.tl.add( 'time0', 0.5 );

                for( let i = 0; i < this.from; i++ )
                {
                    this.tl.add( 'time' + ( i + 1 ), 'time' + ( i ) + '+=1.3' );
                    this.tl.fromTo( this.$refs.number[i], 0.3, { scale: 0, rotation: 25 }, { scale: 1, rotation: 0, ease: Back.easeOut }, 'time' + i + '-=0.3' );
                    this.tl.to( this.$refs.number[i], 0.1, { scale: 0, rotation: 25 }, 'time' + ( i + 1 ) + '-=0.4' );
                }
            },

            start()
            {
                this.tl.restart();
            },

            onComplete()
            {
                this.$emit( 'complete' );
            }
        }
    };
</script>
