<template>
    <div class="custom-input">
        <!-- eslint-disable-next-line -->
        <label :for="name" v-if="type !== 'checkbox' && labels" v-html="labels[ name ] + ( isRequired ? '*' : '' )"></label>

        <template v-if="tag === 'input'">
            <input :name="name" :id="id ? id : name" :value="type === 'radio' ? radioValue : value" v-validate="rules ? rules[ name ] : null" :type="type" :data-state="state" @change="onChange" @blur="onBlur" @input="onInput" :placeholder="placeholder" />
            <slot></slot>
            <div class="indicator" v-if="type === 'checkbox' || type === 'radio'"></div>
        </template>

        <template v-else>
            <component :is="tag" :name="name" :id="name" v-validate="rules ? rules[ name ] : null" :type="type" :data-state="state" @change="onChange" @blur="onBlur" @input="onInput" :placeholder="placeholder">
                <slot></slot>
            </component>
        </template>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {
        inject: [ '$validator' ],

        props: {
            name: {
                type: String,
                default: null
            },

            rules: {
                type: Object,
                default: () => {}
            },

            labels: {
                type: Object,
                default: () => {}
            },

            type: {
                type: String,
                default: 'text'
            },

            tag: {
                type: String,
                default: 'input'
            },

            'noLabel': {
                type: Boolean,
                default: false
            },

            placeholder: {
                type: String,
                default: ''
            },

            id: {
                type: String,
                default: null
            },

            radioValue: {
                type: String,
                default: null
            },

            value: {
                type: [String, Boolean],
                default: null
            }
        },

        data()
        {
            return {
                internalValue: this.value,
                // value: null,
                state: 'unverified' // unverified, warning, error, success
            };
        },

        computed: {
            errors()
            {
                return this.$validator.errors;
            },

            isRequired()
            {
                if( !this.rules )
                {
                    return false;
                }
                return this.rules[ this.name ] ? ( this.rules[ this.name ].required ) : false;
            }
        },

        mounted()
        {
            /*
                tabindex
                class (error states)
                autocompletes
            */
        },

        methods: {
            proxyEvent( e )
            {
            },

            onInput( e )
            {
                this.$validator.validate( this.name ).then( result =>
                {
                    // console.log( result, this.state );
                    if( result && this.state === 'warning' )
                    {
                        this.state = 'success';
                    }
                });

                // console.log( 'input' );

                if( this.type === 'checkbox' )
                {
                    this.internalValue = e.target.checked;
                }
                else if( this.type === 'radio' )
                {
                    this.internalValue = this.radioValue;
                }
                else
                {
                    this.internalValue = e.target.value;
                }

                this.$emit( 'input', this.internalValue );
            },

            onBlur( e )
            {
                this.stripSpaces();
                this.$emit( 'blur', e );
            },

            onChange( e )
            {
                this.$validator.validate( this.name ).then( result =>
                {
                    if( !result )
                    {
                        this.state = 'warning';
                    }
                    else
                    {
                        this.state = 'success';
                    }
                });

                if( this.type === 'checkbox' )
                {
                    this.internalValue = e.target.checked;
                }
                else if( this.type === 'radio' )
                {
                    this.internalValue = this.radioValue;
                }
                else
                {
                    this.internalValue = e.target.value;
                }

                this.stripSpaces();

                this.$emit( 'input', this.internalValue );
            },

            log( e )
            {
                e.preventDefault();
            },

            stripSpaces()
            {
                if( !this.internalValue )
                {
                    return;
                }
                if( this.name === 'zipcode' || this.type === 'tel' || this.type === 'email' )
                {
                    this.internalValue = this.internalValue.replace( /[\s ]/g, '' );
                }
            }
        }
    };
</script>
