<template>
    <div class="result-view">
        <div class="yellow-bar">
            <div class="visual">
                <img src="/static/img/bedankt.jpg" />
                <div class="shadow"></div>
            </div>
        </div>
        <div class="other-content">
            <h2>Bedankt voor het meespelen!</h2>
            <p>Je maakt nu kans op <strong>&euro;&nbsp;{{ dottify( moneyCollected ) }}!</strong></p>

            <p>Je mag altijd nog een keer spelen om <template v-if="moneyCollected < 4500">dit bedrag te verhogen of</template> je puntenscore van <strong>{{ dottify( score ) }}</strong> te verbeteren. <br/>Je speelt automatisch mee met het hoogste bedrag en je behaalde puntenscore heeft geen invloed op de winkans.</p>

            <button class="replay-button" @click="playAgain">Speel opnieuw</button>

            <h2>Mooie score!</h2>
            <p>Vind jij het spelletje ook zo leuk en wil jij laten zien hoe goed jouw score is? Deel je score met je vrienden en daag ze uit!</p>
            <v-social></v-social>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .other-content{
        p{
            strong{
                line-height:1;
            }
        }
    }
</style>

<script>
    import Social from '@/app/components/Social';
    import { dottify, tracking } from '@/app/mixins';

    export default {
        components: {
            'v-social': Social
        },

        mixins: [ dottify, tracking ],

        data()
        {
            return {
            };
        },

        computed: {
            moneyCollected()
            {
                return this.$store.getters[ 'game/moneyCollected' ];
            },

            maxMoneyCollected()
            {
                return this.moneyCollected === 4500;
            },

            score()
            {
                return this.$store.getters[ 'game/score' ];
            }
        },

        mounted()
        {
            this.$store.dispatch( 'game/setHighscore', this.score );
        },

        methods: {
            playAgain( e )
            {
                this.$router.replace( '/game' );
            }
        }
    };
</script>
