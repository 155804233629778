import Paddle from '@/app/game/elements/Paddle';
import Ball from '@/app/game/elements/Ball';
import Block from '@/app/game/elements/Block';
import CollisionDetector from '@/app/game/handlers/CollisionDetector';
import EventHub from '@/app/game/handlers/EventHub';
import Victor from 'victor';
import ScorePlopper from '@/app/game/elements/ScorePlopper';

export default class World extends PIXI.Container
{
    constructor()
    {
        super();

        this.eventHub = EventHub;
        this.lives = 3;

        this.ballArray = [];
        this.ball = new Ball({ x: 860, y: 1775 }); // 860
        this.ball.id = 0;
        this.ballArray.push( this.ball );

        this.paddle = new Paddle();

        this.addChild( this.ball );
        this.addChild( this.paddle );

        this.blockList = [];
        this.blockColors = [ 0xffdd00, 0xd1d3d4, 0xe6333f, 0x5fc4e1, 0x9b86be, 0x9b86be ];
        this.removeList = [];

        // this.streak = 0;

        let width = 100;
        let height = 50;
        let gutter = 2;
        let rows = 16;
        let cols = 8;
        let offsetX = ( 1080 - width * cols ) / 2;
        let offsetY = offsetX;

        /*
        blocktype:
        0: normal
        1: solid
        2: prize
        3: score
        4: paddle
        5: extra ball
        */

        let nSolidBlocks = 3;
        let nPrizeBlocks = 4;
        let nScoreBlocks = 10;
        let nPaddleBlocks = 1;
        let nBallBlocks = 1;

        let solidBlocks = [];
        let prizeBlocks = [];
        let scoreBlocks = [];
        let paddleBlocks = [];
        let ballBlocks = [];

        let skipList = [ 0, 1, 6, 7, 8, 9, 14, 15, 16, 23, 24, 31, 96, 103, 104, 111, 112, 113, 118, 119, 120, 121, 126, 127 ]; // skip these to make a ball

        while( solidBlocks.length <= nSolidBlocks )
        {
            let num = Math.floor( Math.random() * ( rows * cols ) );
            if( solidBlocks.indexOf( num ) === -1 && skipList.indexOf( num ) === -1 )
            {
                solidBlocks.push( num );
            }
        }

        while( prizeBlocks.length <= nPrizeBlocks )
        {
            let num = Math.floor( Math.random() * ( rows * cols ) );
            if( prizeBlocks.indexOf( num ) === -1 && solidBlocks.indexOf( num ) === -1 && skipList.indexOf( num ) === -1 )
            {
                prizeBlocks.push( num );
            }
        }

        while( scoreBlocks.length <= nScoreBlocks )
        {
            let num = Math.floor( Math.random() * ( rows * cols ) );
            if( scoreBlocks.indexOf( num ) === -1 && prizeBlocks.indexOf( num ) === -1 && solidBlocks.indexOf( num ) === -1 && skipList.indexOf( num ) === -1 )
            {
                scoreBlocks.push( num );
            }
        }

        while( paddleBlocks.length <= nPaddleBlocks )
        {
            let num = Math.floor( Math.random() * ( rows * cols ) );
            if( paddleBlocks.indexOf( num ) === -1 && scoreBlocks.indexOf( num ) === -1 && prizeBlocks.indexOf( num ) === -1 && solidBlocks.indexOf( num ) === -1 && skipList.indexOf( num ) === -1 )
            {
                paddleBlocks.push( num );
            }
        }

        while( ballBlocks.length <= nBallBlocks )
        {
            let num = Math.floor( Math.random() * ( rows * cols ) );
            if( ballBlocks.indexOf( num ) === -1 && paddleBlocks.indexOf( num ) === -1 && scoreBlocks.indexOf( num ) === -1 && prizeBlocks.indexOf( num ) === -1 && solidBlocks.indexOf( num ) === -1 && skipList.indexOf( num ) === -1 )
            {
                ballBlocks.push( num );
            }
        }

        for( let i = 0; i < rows; i++ )
        {
            for( let j = 0; j < cols; j++ )
            {
                if( skipList.indexOf( j + i * cols ) !== -1 )
                {
                    continue;
                }

                let blockType = 0;

                if( solidBlocks.indexOf( j + i * cols ) > 0 )
                {
                    blockType = 1;
                }

                if( prizeBlocks.indexOf( j + i * cols ) > 0 )
                {
                    blockType = 2;
                }

                if( scoreBlocks.indexOf( j + i * cols ) > 0 )
                {
                    blockType = 3;
                }

                if( paddleBlocks.indexOf( j + i * cols ) > 0 )
                {
                    blockType = 4;
                }

                if( ballBlocks.indexOf( j + i * cols ) > 0 )
                {
                    blockType = 5;
                }

                let block = new Block({
                    id: j + i * cols,
                    x: offsetX + j * ( width + gutter ),
                    y: offsetY + i * ( height + gutter ),
                    w: width,
                    h: height,
                    blockType: blockType,
                    color: this.blockColors[ blockType ],
                    points: blockType === 3 ? 90 - ( Math.floor( i / 2 ) ) * 10 : null
                });

                this.blockList.push( block );
            }
        }
        // remove below
        // let block2 = new Block({
        //     id: 200,
        //     x: 400,
        //     y: 1300,
        //     w: 100,
        //     h: height,
        //     blockType: 2,
        //     color: this.blockColors[ 3 ],
        //     points: null
        // });
        // this.blockList.push( block2 );

        // let block3 = new Block({
        //     id: 201,
        //     x: 557,
        //     y: 1400,
        //     w: width,
        //     h: height,
        //     blockType: 2,
        //     color: this.blockColors[ 3 ],
        //     points: null
        // });
        // this.blockList.push( block3 );

        // let block4 = new Block({
        //     id: 202,
        //     x: 657 + gutter,
        //     y: 1450,
        //     w: width,
        //     h: height,
        //     blockType: 2,
        //     color: this.blockColors[ 3 ],
        //     points: null
        // });
        // this.blockList.push( block4 );

        // let block5 = new Block({
        //     id: 202,
        //     x: 657 + gutter,
        //     y: 1400,
        //     w: width,
        //     h: height,
        //     blockType: 2,
        //     color: this.blockColors[ 3 ],
        //     points: null
        // });
        // this.blockList.push( block5 );

        // remove above

        for( let i in this.blockList )
        {
            this.addChild( this.blockList[i] );
        }

        this.detector = new CollisionDetector( this.blockList );
        this.ticksLeft = null;
        this.paddleTicksLeft = null;

        this.eventHub.on( 'collisionAfter', this.setCollisionVars.bind( this ) );
        this.eventHub.on( 'paddleCollisionAfter', this.setPaddleVars.bind( this ) );
        this.eventHub.on( 'requestCollisionCheck', this.detector.check.bind( this.detector ) );
        this.eventHub.on( 'requestPaddleCheck', this.proxyPaddleCheck.bind( this ) );
        this.eventHub.on( 'countBlocks', this.countBlocks.bind( this ) );
        this.eventHub.on( 'checkMaxPrizeReached', this.checkMaxPrizeReached.bind( this ) );
        this.eventHub.on( 'resetBall', this.resetBall.bind( this ) );
        this.eventHub.on( 'releaseBall', this.releaseBall.bind( this ) );
        this.eventHub.on( 'releaseNewBall', this.releaseNewBall.bind( this ) );

        this.pauseAfterNCollisions = null;
    }

    proxyPaddleCheck( pBall )
    {
        this.detector.checkPaddle( pBall, this.paddle );
    }

    update()
    {
        for( let i = 0; i < this.ballArray.length; i++ )
        {
            this.ballArray[i].update();
            if( !this.ballArray[i] )
            {
                // if died in update function
                continue;
            }

            if( this.ballArray[i].ticksLeft !== null )
            {
                this.playOutCollision( this.ballArray[i] );
            }
            else if( this.ballArray[i].ticksLeft === null && this.ballArray[i].paddleTicksLeft !== null )
            {
                this.playOutPaddleCollision( this.ballArray[i] );
            }

            if( this.ballArray[i].isHome )
            {
                this.ballArray[i].spd = 0;
                this.ballArray[i].x = this.paddle.x + this.paddle.w / 2;
                this.ballArray[i].y = this.paddle.y - 20;
            }
        }

        this.paddle.update();
    }

    setCollisionVars( pSettings )
    {
        // console.log( 'pSettings', pSettings );
        pSettings.ballRef.ticksLeft = pSettings.ticks;
        pSettings.ballRef.subticks = pSettings.subticks;
        pSettings.ballRef.newBallPos = pSettings.pos;
        pSettings.ballRef.newBallDir = pSettings.dir;
        pSettings.ballRef.removeList = pSettings.collisionList;
    }

    setPaddleVars( pRtn )
    {
        pRtn.ballRef.paddleTicksLeft = pRtn.ticks;
        pRtn.ballRef.subTicksAfterPaddle = pRtn.subticks;
        pRtn.ballRef.ballPosAfterPaddle = pRtn.ballPos;
    }

    playOutCollision( pBall )
    {
        if( pBall.ticksLeft > 0 )
        {
            pBall.ticksLeft--;
        }
        else
        {
            this.pauseAfterNCollisions--;

            let remaining = pBall.spd - pBall.subticks;

            pBall.x = pBall.newBallPos.x;
            pBall.y = pBall.newBallPos.y;

            pBall.dir = pBall.newBallDir.normalize();
            pBall.resolveRays();

            pBall.ticksLeft = null;

            pBall.spd += 0.1;

            pBall.x += pBall.dir.x * remaining;
            pBall.y += pBall.dir.y * remaining;

            if( this.pauseAfterNCollisions === 0 )
            {
                // console.log( 'had remaining', remaining );
                // console.log( pBall.ray1.x + pBall.x, pBall.ray1.y + pBall.y );
                // console.log( pBall.ray2.x + pBall.x, pBall.ray2.y + pBall.y );
                // console.log( this.removeList[0].x, this.removeList[0].y + this.removeList[0].h );
                pBall.spd = 0;
                return;
            }

            for( let item of pBall.removeList )
            {
                switch( item.blockType )
                {
                    case 0:
                        item.points = pBall.streak > 0 ? ( pBall.streak + 1 ) * 100 : 10;
                        break;
                    case 1:
                        item.points = 0;
                        break;
                    case 2:
                        // fallthrough
                    case 3:
                        // fallthrough
                    case 4:
                        // fallthrough
                    case 5:
                        item.points = pBall.streak > 0 ? ( pBall.streak + 1 ) * 100 : 0;
                        break;
                }

                if( item.points > 0 && item.blockType !== 1 )
                {
                    let scorePlopper = new ScorePlopper({ x: item.x + item.w / 2, y: item.y + item.h * 1.5, payload: { score: '+' + item.points } });
                    this.addChild( scorePlopper.sprite );
                }

                this.eventHub.emit( 'collectedScore', item.points );

                if( item.blockType !== 1 )
                {
                    pBall.streak++;
                }

                this.setChildIndex( item, this.children.length - 1 );

                item.kill( this.paddle );
            }

            this.detector.check( pBall, [pBall.ray1, pBall.ray2] );
            this.detector.checkPaddle( pBall, this.paddle );
        }
    }

    playOutPaddleCollision( pBall )
    {
        if( pBall.paddleTicksLeft > 0 )
        {
            pBall.paddleTicksLeft--;
            if( pBall.y > 1690 && pBall.y < 1740 )
            {
                pBall.paddleTicksLeft = 0;
                pBall.ballPosAfterPaddle.x = pBall.x;
                pBall.ballPosAfterPaddle.y = pBall.y;
            }
        }
        else
        {
            pBall.streak = 0;

            if( pBall.x > this.paddle.x - pBall.ballSize &&
                pBall.x < this.paddle.x + this.paddle.w + pBall.ballSize &&
                pBall.y < this.paddle.y + this.paddle.collisionObj.h &&
                pBall.dir.y > 0 )
            {
                let angle = Math.PI / 4;
                let xPercent = ( pBall.x - this.paddle.x - ( this.paddle.w / 2 ) ) / ( this.paddle.w / 2 );
                let paddleV = new Victor( 0, -1 );
                paddleV.rotate( xPercent * angle );

                pBall.x = pBall.ballPosAfterPaddle.x;
                pBall.y = pBall.ballPosAfterPaddle.y;

                pBall.changeDir( paddleV );

                pBall.x += paddleV.x * pBall.subTicksAfterPaddle;
                pBall.y += paddleV.y * pBall.subTicksAfterPaddle;
            }

            this.pauseAfterNCollisions--;

            if( this.pauseAfterNCollisions === 0 )
            {
                // console.log( 'had remaining', remaining );
                // console.log( pBall.ray1.x + pBall.x, pBall.ray1.y + pBall.y );
                // console.log( pBall.ray2.x + pBall.x, pBall.ray2.y + pBall.y );
                // console.log( this.removeList[0].x, this.removeList[0].y + this.removeList[0].h );
                pBall.spd = 0;
                return;
            }

            pBall.paddleTicksLeft = null;
        }
    }

    resetBall( pBall )
    {
        if( this.ballArray.length > 1 )
        {
            let ballIndex = null;
            let droppedBall = this.ballArray.find( ( item, index ) =>
            {
                if( item.id === pBall.id )
                {
                    ballIndex = index;
                    return true;
                }
                return false;
            });

            this.removeChild( droppedBall );
            this.ballArray.splice( ballIndex, 1 );

            if( this.ballArray.length === 1 && this.ballArray[0].isBall7 )
            {
                this.eventHub.emit( 'changeToBall7' );
            }

            return;
        }

        this.lives--;
        pBall.reset( this.lives );
        this.eventHub.emit( 'ballsLeft', this.lives );
    }

    releaseBall()
    {
        this.ballArray[0].release( 12 );
    }

    releaseNewBall( pPos )
    {
        let newBall = new Ball({ x: pPos.x, y: pPos.y, isBall7: true });
        newBall.id = this.ballArray.length;

        this.addChild( newBall );
        newBall.release( 12 );
        this.ballArray.push( newBall );
    }

    countBlocks()
    {
        if( this.blockList.filter( item => !item.dead && item.blockType !== 1 ).length === 0 )
        {
            this.eventHub.emit( 'gameover' );
        }
    }

    checkMaxPrizeReached()
    {
        if( this.blockList.filter( item => !item.dead && item.blockType === 2 ).length === 0 )
        {
            this.eventHub.emit( 'maxCollectedMoneyReached' );
        }
    }
}
