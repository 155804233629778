export default {
    namespaced: true,

    state: {
        score: 0,
        moneyCollected: 900,
        highscore: 0
    },

    getters: {
        score: state => state.score,
        moneyCollected: state => state.moneyCollected,
        highscore: state => state.highscore
    },

    mutations: {
        addScore: ( state, payload ) =>
        {
            state.score += payload;
            if( state.highscore > 0 && state.score > state.highscore )
            {
                state.highscore = state.score;
            }
        },

        addPrizeMoney: ( state, payload ) =>
        {
            state.moneyCollected = Math.min( 4500, state.moneyCollected + payload );
        },

        resetScores: state =>
        {
            state.moneyCollected = 900;
            state.score = 0;
        },

        setHighscore: ( state, payload ) =>
        {
            if( payload > state.highscore )
            {
                state.highscore = payload;
            }
        }
    },

    actions: {
        addScore( context, payload )
        {
            context.commit( 'addScore', payload );
        },

        addPrizeMoney( context, payload )
        {
            context.commit( 'addPrizeMoney', payload );
        },

        resetScores( context )
        {
            context.commit( 'resetScores' );
        },

        setHighscore( context, payload )
        {
            if( !payload )
            {
                context.commit( 'setHighscore', context.state.score );
            }
            else
            {
                context.commit( 'setHighscore', payload );
            }
        }
    }
};
