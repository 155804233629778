import EventEmitter from 'eventemitter3';
import EventHub from '@/app/game/handlers/EventHub';
import InputHandler from '@/app/game/handlers/InputHandler';

import DebugPanel from '@/app/game/DebugPanel';

import World from '@/app/game/elements/World';

export default class Breakout extends EventEmitter
{
    constructor( settings )
    {
        super();

        PIXI.utils.skipHello();
        PIXI.utils.clearTextureCache();

        this.view = settings.view;
        this.app = new PIXI.Application( 1080, 1920, { view: this.view, transparent: true, legacy: settings.legacy });

        this.debugPanel = new DebugPanel({ appRef: this.app });

        this.eventHub = EventHub;
        this.inputHandler = new InputHandler({ view: this.view });
        // preload. and then

        this.checkResize();
        this.startApp();
    }

    startApp()
    {
        this.world = new World();
        this.app.stage.addChild( this.world );

        // this.app.stage.addChild( this.debugPanel );

        this.app.ticker.add( this.world.update.bind( this.world ) );
        this.app.ticker.add( this.debugPanel.update.bind( this.debugPanel ) );
    }

    stop()
    {
        this.app.ticker.stop();
    }

    start()
    {
        this.app.ticker.start();
    }

    checkResize()
    {
        this.app.renderer.resize( 1080, 1920 );
    }

    handleInput( e )
    {
        this.inputHandler.interaction( e );
    }

    destroy()
    {
        this.app.destroy();
        this.eventHub.removeAllListeners();
    }
}
