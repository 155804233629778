<template>
    <div class="lotto-ball" >
        <!-- <img class="ball-shadow" src="/static/img/game/ball-shadow.png" /> -->
        <img class="empty-ball" :src="'/static/img/game/'+ bgImg +'.png'" />
        <transition v-enter="onEnter">
            <img v-show="dropped" class="empty-ball-red" :src="'/static/img/game/ball-half.png'" />
        </transition>

        <div class="number-cap">
            <svg class="number" ref="number" viewBox="0 0 175 175" width="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" >
                <g>
                    <text text-anchor="middle" x="50%" y="63%" font-size="40px">{{ number }}</text>
                    <line x1="20%" y1="69%" x2="80%" y2="69%" stroke="black" stroke-width="5" ></line>
                </g>
            </svg>
        </div>

        <img v-if="ballType === 'lotto'" class="glow" src="/static/img/game/ball-glow.png" />
        <img v-else-if="dropped === true" class="glow" src="/static/img/game/ball-special-glow.png" />
    </div>
</template>

<style lang="scss" scoped>
    .number{
        will-change:transform;
    }
    .number > g{
        will-change:transform;
        transform-origin:50% 50%;
    }

    .dropped-overlay{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        padding-bottom:100%;
        height:0;
        background-color:#e6333f;
        border-radius:1000px;
    }

    .empty-ball-red{
        position:absolute;
        top:0;
        left:0;
    }
</style>

<script>
    import { TweenMax, TimelineMax } from '@/vendor';

    export default {
        props: {
            'number': {
                type: Number,
                default: 42
            },
            'ballType': {
                type: String,
                default: 'lotto' // double, red
            },
            'dropped': {
                type: Boolean,
                default: false
            }
        },

        data()
        {
            return {
                size: window.innerHeight * 0.1694,
                rollAnim: null,
                distance: 2,
                timeElapsed: 1
            };
        },

        computed: {
            bgImg()
            {
                switch( this.ballType )
                {
                        case 'double':
                            return 'ball-double';
                        case 'red':
                            return 'ball-half';
                        default:
                            return 'ball-empty';
                }
            }
        },

        watch: {
            number: function( pVal, pOldVal )
            {
                this.animIn();
            },

            dropped: function( pVal )
            {
                this.onEnter();
            }
        },

        methods: {
            onEnter()
            {
                TweenMax.from( this.$el.querySelector( '.empty-ball-red' ), 0.3, { delay: 0.3, autoAlpha: 0 });
                this.animIn();
            },

            animIn()
            {
                if( this.tl )
                {
                    this.tl.restart( 0 );
                    return;
                }

                this.tl = new TimelineMax({
                    delay: 0
                });

                var el = this.$el;
                let ballGroup = el.querySelector( '.number' );

                TweenMax.set( ballGroup, { transformOrigin: '87.5px 87.5px 0px', rotation: 0.001 });

                let xDist = 175;

                let singleSpinTween = new TimelineMax({ repeat: -1 });
                singleSpinTween.fromTo( ballGroup, 1, { x: xDist }, { x: -xDist, ease: Linear.easeNone }, 0 );

                let multiSpinTween = new TimelineMax({ paused: true });
                multiSpinTween.add( singleSpinTween );

                this.tl.to( multiSpinTween, 1, { time: 1.5, ease: Power2.Out }, 0 );
            }
        }
    };
</script>
