import { TimelineMax, TweenMax } from '@/vendor';
import EventHub from '@/app/game/handlers/EventHub';
import ScorePlopper from '@/app/game/elements/ScorePlopper';

export default class Block extends PIXI.Sprite
{
    constructor( settings )
    {
        /*
        blocktype:
        0: normal
        1: solid
        2: prize
        3: score
        4: paddle
        5: extra ball
        */

        super();

        this.id = settings.id;
        this.eventHub = EventHub;

        this.blockType = settings.blockType;

        this.x = settings.x;
        this.y = settings.y;
        this.w = settings.w;
        this.h = settings.h;

        this.brick = new PIXI.Sprite();

        this.glow = PIXI.Sprite.fromImage( '/static/img/game/glow_' + Math.min( this.blockType, 4 ) + '.png' );
        this.glow.anchor.set( 0.5 );

        this.glow.x = this.w / 2;
        this.glow.y = this.h / 2;

        this.glow.alpha = 0;

        this.addChild( this.brick );
        this.addChild( this.glow );

        this.graphic = new PIXI.Graphics();
        this.anchor.set( 0.5 );

        let color = settings.color ? settings.color : 0xffdd00;
        if( this.blockType === 1 )
        {
            color = 0xefefef;
        }

        this.points = 10;

        if( this.blockType === 3 )
        {
            this.extraPoints = settings.points;
        }

        this.graphic.beginFill( color );
        this.graphic.drawRect( 0, 0, settings.w, settings.h );

        this.graphic.beginFill( 0x414042 );
        this.graphic.drawRect( settings.w - 2, 0, 2, settings.h );
        this.graphic.drawRect( 0, settings.h - 2, settings.w, 2 );

        this.graphic.beginFill( 0xffffff );
        this.graphic.drawRect( 0, 0, 2, settings.h );
        this.graphic.drawRect( 0, 0, settings.w, 2 );

        this.textStyle = {
            align: 'center',
            fill: 0xffffff,
            fontSize: 32,
            fontWeight: 'bold',
            wordWrap: true
        };

        if( this.blockType === 2 )
        {
            this.blockText = new PIXI.Text( '€ 900', this.textStyle );
        }

        if( this.blockType === 3 )
        {
            this.blockText = new PIXI.Text( '+' + this.extraPoints, this.textStyle );
        }

        if( this.blockType === 4 )
        {
            this.graphic.drawRoundedRect( 5, 19, settings.w - 10, 16, 8 );
        }

        this.brick.addChild( this.graphic );

        if( this.blockType === 5 )
        {
            this.tinyball = PIXI.Sprite.fromImage( '/static/img/game/ball7.png' );
            this.tinyball.anchor.set( 0.5 );
            this.tinyball.x = this.w / 2;
            this.tinyball.y = this.h / 2;
            this.brick.addChild( this.tinyball );
        }

        if( this.blockType === 2 || this.blockType === 3 )
        {
            this.blockText.anchor.set( 0.5 );
            this.blockText.x = settings.w / 2;
            this.blockText.y = settings.h / 2;

            this.brick.addChild( this.blockText );
        }

        this.dead = false;
    }

    get rect()
    {
        return { x: this.x, y: this.y, w: this.w, h: this.h };
    }

    beforeKill()
    {
        return new Promise( resolve =>
        {
            this.dead = true;

            if( this.blockType === 5 )
            {
                this.eventHub.emit( 'extraBall', { x: this.brick.x, y: this.brick.y });
            }

            let tl = new TimelineMax({ onComplete: resolve });
            let x = Math.random() * 100 - 50;
            let rot = Math.random() * 4 - 2;

            if( this.points !== 10 )
            {
                tl.fromTo( this.glow, 1.5, { alpha: 1 }, { alpha: 0 }, 0 );
            }

            tl.to( this.brick, 0.7, { x: '+=' + x, rotation: '+=' + rot, ease: Sine.easeIn }, 0 );
            tl.to( this.brick, 0.7, { y: '+=150', ease: Back.easeIn.config( 1 ) }, 0 );
            tl.to( this.brick, 0.2, { alpha: 0 }, 0.4 - 0.1 );
        });
    }

    fallDown( pPaddleRef )
    {
        return new Promise( resolve =>
        {
            // this.eventHub.emit( 'collectedScore', this.points );
            this.zIndex = 100;
            this.dead = true;

            this.fallTl = new TimelineMax({
                onComplete: resolve
            });

            let x = Math.random() * 100 - 50;
            let rot = Math.random() * 4 - 2;

            let distance = 2300 - this.brick.y;
            let timePer100 = 0.3;
            let time = distance / 100 * timePer100;

            this.fallTween = TweenMax.to( this.brick, time, {
                paused: true,
                y: distance,
                ease: Back.easeIn.config( 1 ),
                onUpdate: () =>
                {
                    if( this.fallTween.target.y + this.y >= 1650 && this.fallTween.target.y + this.y < 1750 )
                    {
                        if( this.rect.x > pPaddleRef.x - this.rect.w && this.rect.x < pPaddleRef.x + pPaddleRef.w )
                        {
                            this.handleCaught( resolve );
                            this.fallTl.kill();
                        }
                    }
                }
            }, 0 );

            if( this.points !== 10 )
            {
                this.fallTl.fromTo( this.glow, 1.5, { alpha: 1 }, { alpha: 0 }, 0 );
            }

            this.fallTl.to( this.brick, time, { x: '+=' + x, rotation: '+=' + rot, ease: Sine.easeIn }, 0 );
            this.fallTl.fromTo( this.fallTween, time - 1, { progress: 0 }, { progress: 1, ease: Power2.easeOut }, 0 );
        });
    }

    handleCaught( pResolve )
    {
        if( this.blockType === 2 )
        {
            this.eventHub.emit( 'collectedMoney' );
        }
        else if( this.blockType === 3 )
        {
            this.eventHub.emit( 'collectedScore', this.extraPoints );
        }
        else if( this.blockType === 4 )
        {
            this.eventHub.emit( 'biggerPaddle' );
        }

        if( this.blockText )
        {
            let scorePlopper = new ScorePlopper({ x: this.x + this.brick.x + this.w / 2, y: this.y + this.brick.y + this.h * 1.5, payload: { score: this.blockText.text } });
            this.parent.addChild( scorePlopper.sprite );
        }

        TweenMax.set( this.glow, { x: this.brick.x + this.w / 2, y: this.brick.y + this.h / 2, rotation: this.brick.rotation });

        let smallTl = new TimelineMax({ onComplete: pResolve });
        smallTl.fromTo( this.glow, 1, { alpha: 1 }, { alpha: 0 }, 0 );
        smallTl.to( this.brick, 0.3, { alpha: 0 }, 0 );
    }

    async kill( pPaddleRef )
    {
        if( this.blockType === 1 )
        {
            return;
        }

        if( this.blockType === 5 )
        {
            this.dead = true;
            this.eventHub.emit( 'releaseNewBall', { x: this.x, y: this.y });
        }

        if( this.blockType === 0 || this.blockType === 5 )
        {
            await this.beforeKill();
        }

        if( this.blockType === 2 || this.blockType === 3 || this.blockType === 4 )
        {
            await this.fallDown( pPaddleRef );
        }

        if( this.blockType === 2 )
        {
            this.eventHub.emit( 'checkMaxPrizeReached' );
        }

        this.brick.alpha = 0;
        this.graphic.alpha = 0;

        this.eventHub.emit( 'countBlocks' );
    }
}
