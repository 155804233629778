<template>
    <div class="landing-view">
        <!-- <h1>Lotto &lsquo;Bricks&rsquo; is helaas afgelopen</h1> -->
        <!-- <p>Je kan nu onze nieuwe actie &lsquo;Lotto Catch 45&rsquo; spelen!</p> -->
        <!-- <a class="button black" href="https://lotto-catch45.nederlandseloterij.nl?r=bricks">Speel Catch 45</a> -->

        <h1>Speel Lotto Bricks<br/><strong>en maak kans op &euro;&nbsp;4.500!</strong></h1>

        <div class="campaign-img">
            <img class="cb" src="/static/img/campaign-img/bricks-campaign.png" />
            <img ref="paddle" class="paddle" src="/static/img/campaign-img/paddle.png" />
            <div class="ball-wrapper">
                <img ref="tail" class="ball-tail" src="/static/img/campaign-img/ball45-tail.png" />
                <img ref="ball" class="ball" src="/static/img/campaign-img/ball45.png" />
            </div>

            <img class="c-letter" src="/static/img/campaign-img/c-letter.png" />
        </div>
        <button class="black" @click="toExplain" >Speel nu</button>
        <button v-if="!isStandalone" @click="promptAddToHome">Add to Homescreen</button>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import screenfull from 'screenfull';
    import { browserCheck } from '@/app/mixins';
    import { TimelineMax, TweenMax } from '@/vendor';

    export default {
        name: 'Landing',

        mixins: [ browserCheck ],

        data()
        {
            return {
                isStandalone: ( 'fullscreen' in window.navigator ) && ( window.navigator.fullscreen )
            };
        },

        mounted()
        {
        },

        methods: {
            toExplain()
            {
                if( screenfull.enabled && ( this.browserCheck.isMobile || window.innerHeight > 1820 ) )
                {
                    screenfull.request();
                }

                this.$router.push( '/game/uitleg' );
            },

            promptAddToHome()
            {
                if( window._pwaInstallEvent )
                {
                    window._pwaInstallEvent.prompt();
                }
            },

            getAnimateIn()
            {
                let tl = new TimelineMax({ delay: 1 });

                TweenMax.set( [ this.$refs.ball, this.$refs.tail ], { transformOrigin: '100% 0', rotation: 0.001 });

                tl.from( [ this.$refs.paddle, this.$refs.ball ], 0.4, { opacity: 0 }, 0 );

                tl.fromTo( this.$refs.paddle, 2.3, { x: '-=200%' }, { x: '0%', ease: Power2.easeInOut }, 0 );

                tl.from( this.$refs.ball, 1.5, { rotation: '-120deg' }, 0 );
                tl.fromTo( [ this.$refs.ball, this.$refs.tail ], 1.5, { right: '90%', top: '20%', scale: 0.1 }, { right: '60%', top: '83%', scale: 0.2, ease: Sine.easeIn }, 0 );

                tl.to( [ this.$refs.ball, this.$refs.tail ], 1, { right: '28.034%', top: '6.444%', scale: 1 }, 1.5 );
                tl.from( this.$refs.tail, 1, { opacity: 0 }, 1.75 );

                return tl.timeScale( 1.5 );
            }
        }
    };
</script>
