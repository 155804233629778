import EventEmitter from 'eventemitter3';

class EventHub extends EventEmitter
{
    updateMouse()
    {
    }
}

export default new EventHub();
