<template>
    <form :class="{ 'lotto-user': isLottoUser }" @submit.prevent="onSubmit">

        <div class="row">
            <div class="col-12">
                <v-gender-input v-model="naw.gender"></v-gender-input>
            </div>
        </div>

        <div class="row">
            <div class="col-8">
                <v-form-elem input="firstname" v-model="naw.firstname" tabindex="1"/>
            </div>
            <div class="col-4">
                <v-form-elem input="infixname" v-model="naw.infixname" tabindex="2"/>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <v-form-elem input="surname" v-model="naw.surname" tabindex="3"/>
            </div>
        </div>

        <div class="row">
            <div class="col-3">
                <div class="form-input">
                    <label for="day_of_birth">Geboortedatum</label>
                    <select name="day_of_birth" v-model="naw.day_of_birth" tabindex="4">
                        <option value="" disabled>dag</option>
                        <option v-for="i in 31" :selected="i === naw.day_of_birth">{{ i }}</option>
                    </select>
                </div>
            </div>
            <div class="col-3">
                <div class="form-input">
                    <label for="month_of_birth">&nbsp;</label>
                    <select name="month_of_birth" v-model="naw.month_of_birth" tabindex="5">
                        <option value="" disabled>maand</option>
                        <option v-for="i in 12" :selected="i === naw.month_of_birth" :value="i">{{ months[i-1] }}</option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-input">
                    <label for="year_of_birth">&nbsp;</label>
                    <select name="year_of_birth" v-model="naw.year_of_birth" tabindex="6">
                        <option value="" disabled>jaar</option>
                        <option v-for="i in 100" :selected="( 2001 - i ) === naw.year_of_birth">{{ ( 2001 - i ) }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <input type="hidden" name="date_of_birth" v-model="naw.date_of_birth" v-validate="'required|date_valid'">
                <span class="error text-center" v-if="birthdateError" style="width: 100%; display:inline-block;">Dit is geen geldige geboortedatum.</span>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <v-form-elem input="zipcode" @blur="checkAddress" v-model="naw.zipcode" tabindex="7" />
            </div>
            <div class="col-3">
                <v-form-elem input="house_num" @blur="checkAddress" v-model="naw.house_num" tabindex="8" />
            </div>
            <div class="col-3">
                <v-form-elem input="house_num_add" @blur="checkAddress" v-model="naw.house_num_add" tabindex="9" />
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <span class="error text-center" v-if="addressError" style="width: 100%; display:inline-block;">Adres niet gevonden.</span>
                <div class="address-preview" v-if="( naw.street && naw.city ) || waitingForAddress">
                    <v-spinner v-if="waitingForAddress"></v-spinner>
                    <span v-if="naw.street && naw.city">{{ naw.street + ', ' + naw.city }}</span>
                </div>
            </div>
        </div>

        <div class="row" v-if="!isLottoUser">
            <div class="col-12">
                <v-form-elem input="telephone" @blur="stripSpaces" v-model="naw.telephone" tabindex="10" />
                <p class="small">Vul je telefoonnnummer in en ontvang eenmalig een telefonisch aanbod van Lotto om automatisch mee te spelen.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <v-form-elem input="emailaddress" @blur="stripSpaces" v-model="naw.emailaddress" tabindex="11" />
            </div>
        </div>

        <div class="row" v-if="!isLottoUser">
            <div class="col-12">
                <v-form-elem class="kit-checkbox" input="kit" inputType="checkbox" v-model="naw.kit" tabindex="12">Ja, ik ontvang graag per e-mail interessante acties en aanbiedingen van Lotto.</v-form-elem>
            </div>
        </div>

        <!-- <div class="row" >
            <div class="col-12">
                <v-form-elem class="age-checkbox" input="age" inputType="checkbox" v-model="naw.age">Ja, ik ben 18 jaar of ouder en ga akkoord met de <a @click="trackAv" href="/static/legal/actievoorwaarden.pdf" target="_blank">actievoorwaarden</a></v-form-elem>
            </div>
        </div> -->

        <div class="row">
            <div class="col-12">
                <span class="error text-center" v-if="error">Niet alle velden zijn correct ingevuld. Controleer de gemarkeerde velden en probeer het nog eens</span>
                <span class="error text-center" v-if="apiError">Oeps. Er is helaas iets mis gegaan. Probeer het later nog eens.</span>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <button type="submit" class="white" :style="{ marginTop: isLottoUser ? '1rem' : '0' }" :disabled="waitingForApi" tabindex="13">Verstuur<v-spinner v-if="waitingForApi"></v-spinner></button>
                <p class="disclaimer small">Door op verstuur te drukken ga je akkoord met de <a @click="trackAv" :href="avLink" target="_blank">actievoorwaarden</a> en het&nbsp;<a href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy&nbsp;Statement</a></p>
            </div>
        </div>
    </form>
</template>

<style lang="scss" scoped>
</style>

<script>
    import Vue from 'vue';
    import Fingerprint2 from 'fingerprintjs2';
    import { TweenMax } from '@/vendor';
    import FormInput from '@/app/components/FormInput';
    import GenderInput from '@/app/components/GenderInput';
    import Spinner from '@/app/components/Spinner';
    import { tracking, browserCheck } from '@/app/mixins';

    export default {
        components: {
            'v-spinner': Spinner,
            'v-form-elem': FormInput,
            'v-gender-input': GenderInput
        },

        mixins: [ tracking, browserCheck ],

        data()
        {
            return {
                error: false,
                apiError: false,
                addressError: false,
                birthdateError: false,

                waitingForApi: false,
                waitingForAddress: false,

                naw: {
                    gender: null,
                    firstname: '',
                    infixname: '',
                    surname: '',
                    zipcode: '',
                    street: '',
                    city: '',
                    house_num: '',
                    house_num_add: '',
                    telephone: '',
                    emailaddress: '',
                    date_of_birth: '',
                    day_of_birth: '',
                    month_of_birth: '',
                    year_of_birth: '',
                    kit: false
                },

                months: [
                    'januari',
                    'februari',
                    'maart',
                    'april',
                    'mei',
                    'juni',
                    'juli',
                    'augustus',
                    'september',
                    'oktober',
                    'november',
                    'december'
                ]
            };
        },

        computed: {
            formattedDateOfBirth()
            {
                // console.log( this.naw.year_of_birth, this.naw.month_of_birth, this.naw.day_of_birth );
                if( this.naw.day_of_birth === '' || this.naw.month_of_birth === '' || this.naw.year_of_birth === '' )
                {
                    return '';
                }
                // console.log( this.naw.year_of_birth + '-' + ( '0' + this.naw.month_of_birth ).slice( -2 ) + '-' + ( '0' + this.naw.day_of_birth ).slice( -2 ) );
                return this.naw.year_of_birth + '-' + ( '0' + this.naw.month_of_birth ).slice( -2 ) + '-' + ( '0' + this.naw.day_of_birth ).slice( -2 );
            },

            isLottoUser()
            {
                if( ( window._rootData.r && window._rootData.r === 'mail' ) || ( window._rootData.r && window._rootData.r === 'mail-base' ) )
                {
                    return true;
                }
                else
                {
                    return false;
                }
            },

            previousEntry()
            {
                return this.$store.getters['entry/get'];
            },

            score()
            {
                return this.$store.getters[ 'game/score' ];
            },

            moneyCollected()
            {
                return this.$store.getters[ 'game/moneyCollected' ];
            },

            isVersion2()
            {
                return this.$store.getters.isVersion2;
            },

            avLink()
            {
                if( this.isVersion2 )
                {
                    return '/static/pdf/actievoorwaarden2.pdf';
                }
                else
                {
                    return '/static/pdf/actievoorwaarden.pdf';
                }
            }
        },

        watch: {
            naw: {
                handler: function( pNew, pOld )
                {
                    if( document.activeElement && document.activeElement.name )
                    {
                        if( document.activeElement.name.indexOf( '_of_birth' ) !== -1 && this.fields[ 'date_of_birth' ].validated )
                        {
                            this.$validator.validate( 'date_of_birth' ).then( result =>
                            {
                                if( result === true )
                                {
                                    this.birthdateError = false;
                                }
                            });
                        }

                        if( this.fields[ document.activeElement.name ] && this.fields[ document.activeElement.name ].validated )
                        {
                            // set validation type 'event' ipv 'blur'
                            this.$validator.validate( document.activeElement.name );
                        }
                    }

                    if( this.error && this.errors.items.length === 0 )
                    {
                        this.error = false;
                    }
                },
                deep: true
            },

            'naw.day_of_birth': function()
            {
                if( this.formattedDateOfBirth )
                {
                    this.naw.date_of_birth = this.formattedDateOfBirth;
                }
            },

            'naw.month_of_birth': function()
            {
                if( this.formattedDateOfBirth )
                {
                    this.naw.date_of_birth = this.formattedDateOfBirth;
                }
            },

            'naw.year_of_birth': function()
            {
                if( this.formattedDateOfBirth )
                {
                    this.naw.date_of_birth = this.formattedDateOfBirth;
                }
            },

            'naw.gender': function( pVal )
            {
                // console.log( 'gender changed' );
                this.$validator.validate( 'gender' );
            }
        },

        mounted()
        {
            Vue.nextTick( () =>
            {
                Fingerprint2.get( components =>
                {
                    var values = components.map( component => component.value );
                    this.fingerprint = Fingerprint2.x64hash128( values.join( '' ), 31 );

                    if( this.isReplay )
                    {
                        this.saveEntry();
                    }
                });
            });

            if( this.previousEntry )
            {
                this.naw = this.previousEntry;
            }
        },

        methods: {
            executeRecaptcha()
            {
                return new Promise( resolve =>
                {
                    window.grecaptcha.execute( window._rootData.recaptchaSitekey, { action: 'register' }).then( function( token )
                    {
                        window.recaptchaToken = token;
                        resolve();
                    });
                });
            },

            checkAddress( e )
            {
                this.stripSpaces( e );
                if( this.lastAddress === this.naw.zipcode + this.naw.house_num + this.naw.house_num_add )
                {
                    return;
                }
                this.addressError = false;
                this.lastAddress = this.naw.zipcode + this.naw.house_num + this.naw.house_num_add;
                if( this.naw.zipcode.length > 5 && this.naw.house_num.length > 0 )
                {
                    let url = window._rootData.apiUrl + '/api/address/' + this.naw.zipcode + '/' + this.naw.house_num;
                    if( this.naw.house_num_add.length > 0 )
                    {
                        url += '/' + this.naw.house_num_add;
                    }
                    this.waitingForAddress = true;
                    Vue.$http.post( url ).then( ( result ) =>
                    {
                        if( result.data.errorCode )
                        {
                            throw new Error( result.data.message );
                        }
                        this.naw.street = result.data.street;
                        this.naw.city = result.data.city;
                        this.waitingForAddress = false;
                    }).catch( ( result ) =>
                    {
                        this.addressError = true;
                        this.naw.street = null;
                        this.naw.city = null;
                        this.waitingForAddress = false;
                    });
                }
            },

            stripSpaces( e )
            {
                this.naw[ e.target.name ] = e.target.value.replace( /[\s ]/g, '' );
                Vue.nextTick( () =>
                {
                    this.$validator.validate( e.target.name, this.naw[ e.target.name ] );
                });
            },

            trackAv()
            {
                this.trackEvent( 'Klik op link - Actievoorwaarden', 'Link tracking', window.location.origin + '/static/pdf/actievoorwaarden.pdf' );
            },

            onSubmit( e )
            {
                this.birthdateError = false;

                this.$validator.validateAll().then( result =>
                {
                    if( result )
                    {
                        if( !this.naw.city || !this.naw.street )
                        {
                            this.addressError = true;
                            throw new Error( 'addressError' );
                        }
                        return this.saveEntry();
                    }
                    throw new Error( 'invalid' );
                })
                    .catch( () =>
                    {
                        // validator error
                        if( this.$validator.errors.has( 'date_of_birth' ) )
                        {
                            this.birthdateError = true;
                        }
                    });

                Vue.nextTick( this.scrollToError );
            },

            scrollToError()
            {
                if( this.$validator.errors.items.length > 0 )
                {
                    let field = this.$validator.errors.items[0].field;

                    if( field === 'date_of_birth' )
                    {
                        field = 'day_of_birth';
                    }

                    let top = this.$el.querySelector( '[name=' + field + ']' ).getBoundingClientRect().top;
                    top += document.documentElement.scrollTop;
                    top -= 100;
                    TweenMax.to( 'html, body', 0.5, { scrollTop: top });
                }
            },

            async saveEntry()
            {
                this.apiError = false;

                await this.executeRecaptcha();

                let entry = Object.assign( this.naw, {
                    'g-recaptcha-response': window.recaptchaToken,
                    'fingerprint': this.fingerprint,
                    'origin': window._rootData.r,
                    'score': this.score,
                    'prize': this.moneyCollected
                });

                this.waitingForApi = true;

                return this.$store.dispatch( 'entry/save', entry )
                    .then( entry =>
                    {
                        if( entry.id )
                        {
                            this.waitingForApi = false;
                            this.$emit( 'formComplete' );
                            // complete callback
                        }
                    })
                    .catch( error =>
                    {
                        console.warn( error );

                        this.waitingForApi = false;

                        for( let item in error.errors )
                        {
                            this.$validator.errors.add({
                                field: item,
                                msg: error.errors[item][0]
                            });
                        }
                        this.apiError = true;
                    });
            }
        }
    };
</script>
