<template>
    <div class="social">
        <a v-if="browserCheck.isMobile" :href="whatsappShareLink" target="_blank" @click="trackWhatsapp"><div class="item" data-platform="whatsapp"></div></a>
        <div @click="shareIntent" data-platform="facebook" class="item"></div>
        <div @click="shareIntent" data-platform="twitter" class="item"></div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { tracking, browserCheck, socialShare } from '@/app/mixins';

    export default {
        components: {

        },

        mixins: [ tracking, browserCheck, socialShare ],

        computed: {
            score()
            {
                return this.$store.getters[ 'game/score' ];
            },

            entryId()
            {
                return this.$store.getters[ 'entry/get' ].id;
            },

            shareUrl()
            {
                return window._rootData.apiUrl + '/share/' + this.entryId;
            },

            whatsappShareLink()
            {
                return this.socialShare.url( 'whatsapp', this.shareUrl, { text: '' + this.shareUrl });
            }
        },

        methods: {
            shareIntent( e )
            {
                let platform = e.target.dataset.platform.replace( /^([a-z])/, r => r.toUpperCase() );
                this.trackEvent( 'Klik op share button - ' + platform, 'Link tracking', this.shareUrl );

                switch( e.target.dataset.platform )
                {
                        case 'facebook':
                            this.socialShare.share( 'facebook', this.shareUrl );
                            break;
                        case 'twitter':
                            this.socialShare.share( 'twitter', this.shareUrl, { text: 'Ik haalde ' + this.score + ' punten in Lotto Bricks! Kan jij het beter? ' + this.shareUrl, hashtags: 'Laatjelotnietliggen' });
                            break;
                        default:
                            break;
                }
            },

            trackWhatsapp( e )
            {
                this.trackEvent( 'Klik op share button - Whatsapp', 'Link tracking', this.whatsappShareLink );
            }
        }
    };
</script>
