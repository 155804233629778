import Vue from 'vue';
import Router from 'vue-router';
import store from '@/app/store';

import Result from '@/app/pages/Result';
import Naw from '@/app/pages/Naw';
import Game from '@/app/pages/Game';
import Explain from '@/app/pages/Explain';
import Landing from '@/app/pages/Landing';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/bedankt',
            name: 'Result',
            component: Result,
            meta: {
                pageType: 'promotion-confirmation'
            }
        },
        {
            path: '/gegevens',
            name: 'Naw',
            component: Naw,
            meta: {
                pageType: 'promotion-leadform'
            },
            beforeEnter: ( to, from, next ) =>
            {
                next( '/' );
            }
        },
        {
            path: '/game',
            name: 'Game',
            component: Game,
            meta: {
                pageType: 'promotion-content'
            },
            children: [
                {
                    path: 'uitleg',
                    name: 'Explain',
                    component: Explain,
                    meta: {
                        pageType: 'promotion-content'
                    }
                }
            ]
        },
        {
            path: '/share/:id',
            name: 'SharePath',
            beforeEnter: ( to, from, next ) =>
            {
                if( window._rootData.scoreToBeat )
                {
                    store.dispatch( 'game/setHighscore', window._rootData.scoreToBeat );
                }

                next( '/' );
            }
        },
        {
            path: '/',
            name: 'Landing',
            component: Landing,
            meta: {
                pageType: 'promotion-landing'
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    dataLayer.push({
        event: 'pageview',
        origin: 'website',
        type: to.meta.pageType,
        virtualPage: to.fullPath
    });

    // window.gtag({
    //     event: 'pageview',
    //     origin: 'website',
    //     type: to.meta.pageType,
    //     virtualPage: to.fullPath
    // });

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
