import EventHub from '@/app/game/handlers/EventHub';
import { TweenMax } from '@/vendor';

export default class Paddle extends PIXI.Sprite
{
    constructor( settings )
    {
        super();

        this.eventHub = EventHub;

        this.eventHub.on( 'newMousePos', this.updateMousePos.bind( this ) );
        this.eventHub.on( 'biggerPaddle', this.handleBiggerPaddle.bind( this ) );

        this.graphic = new PIXI.Graphics();
        this.graphic.beginFill( 0xffffff );
        this.x = 400;
        this.y = 1700;

        this.w = 200;

        this.anchor.set( 0 );

        this.collisionObj = { x: 0, y: this.y, w: 1080, h: 100 };

        this.changePaddleSize();

        this.addChild( this.graphic );

        this.knownMousePos = { x: 750, y: 0 };
    }

    updateMousePos( e )
    {
        this.knownMousePos.x = e.x;
    }

    update( e )
    {
        this.x = this.knownMousePos.x - this.w / 2;

        if( this.x < 0 )
        {
            this.x = 0;
        }

        if( this.x > 1080 - this.w )
        {
            this.x = 1080 - this.w;
        }
    }

    changePaddleSize()
    {
        this.graphic.clear();
        this.graphic.beginFill( 0xffffff );
        this.graphic.drawRoundedRect( 0, 0, this.w, 30, 20 );
    }

    handleBiggerPaddle()
    {
        this.w = 300;
        this.changePaddleSize();

        TweenMax.to( this, 20, { w: 200, ease: Linear.easeNone, onUpdate: this.changePaddleSize.bind( this ) });
    }
}
